// import '@capacitor-community/http';
import { Camera, CameraDirection, CameraResultType, CameraSource } from '@capacitor/camera';
import { IonAlert, IonButton, IonButtons, IonContent, IonFooter, IonHeader, IonIcon, IonItem, IonLabel, IonList, IonLoading, IonModal, IonPage, IonSelect, IonSelectOption, IonTextarea, IonTitle, IonToast, IonToggle, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import imageCompression from 'browser-image-compression';
import { Buffer } from 'buffer';
import { addCircle, arrowBack } from 'ionicons/icons';
import React, { useContext, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { buscarEndereco, empresaAlterar, empresaConfiguracoesCarregar, requestService } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import './ContaAlt.css';

type Props = {
  abrirMenu: boolean;
};

const ContaAlt: React.FC<Props> = (props: Props) => {

  const history = useHistory();

  const [showToast2, setShowToast2] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  const [showLoading, setShowLoading] = useState(true);
  const [showSucesso, setShowSucesso] = useState(false);

  const { loja, setLoja } = useContext(MessageContext);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const [empresaNome, setEmpresaNome] = useState('');
  const [empresaEndereco, setEmpresaEndereco] = useState('');
  const [empresaTelefone, setEmpresaTelefone] = useState('');
  const [empresaLatitude, setEmpresaLatitude] = useState('');
  const [empresaLongitude, setEmpresaLongitude] = useState('');
  const [urlLogo, setUrlLogo] = useState('');
  const [condicoesGerais, setCondicoesGerais] = useState('');


  const [nomeInternoMesaOuComanda, setNomeInternoMesaOuComanda] = useState('');
  const [nomeInternoMesasOuComandas, setNomeInternoMesasOuComandas] = useState('');
  const [imprimirMesaParcial, setImprimirMesaParcial] = useState<boolean>(false);


  const [servicoTipo, setServicoTipo] = useState('');
  const [servicoTipoPlural, setServicoTipoPlural] = useState('');
  const [nomeLinhaDoTempo, setNomeLinhaDoTempo] = useState('');
  const [taxaServico, setTaxaServico] = useState('');
  const [identificacaoPorNumero, setIdentificacaoPorNumero] = useState<boolean>(false);
  const [layoutSimplificado, setLayoutSimplificado] = useState<boolean>(false);
  const [zerarValoresVenda, setZerarValoresVenda] = useState<boolean>(false);
  const [esconderValoresProjeto, setEsconderValoresProjeto] = useState<boolean>(false);
  const [tipoAgendamentoPadrao, setTipoAgendamentoPadrao] = useState('');
  const [servicoPeriodoHoraEmpresa, setServicoPeriodoHoraEmpresa] = useState<boolean>(false);
  const [parametroMostrarHorario, setParametroMostrarHorario] = useState<boolean>(false);
  const [enderecoPrincipalEmpresa, setEnderecoPrincipalEmpresa] = useState<boolean>(false);
  const [enviarGoogleAgendaCliente, setEnviarGoogleAgendaCliente] = useState<boolean>(false);
  const [enviarGoogleAgendaResponsavel, setEnviarGoogleAgendaResponsavel] = useState<boolean>(false);
  const [instrucoesMotorista, setInstrucoesMotorista] = useState('');

  const [enviarMsgClienteCadastro, setEnviarMsgClienteCadastro] = useState({ S: "não" });
  const [enviarMsgClienteProjeto, setEnviarMsgClienteProjeto] = useState({ S: "não" });
  const [enviarMsgResposavelProjeto, setEnviarMsgResposavelProjeto] = useState({ S: "não" });
  const [enviarMsgClienteAgendamento, setEnviarMsgClienteAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelAgendamento, setEnviarMsgResposavelAgendamento] = useState({ S: "não" });
  const [enviarMsgResposavelOperacao, setEnviarMsgResposavelOperacao] = useState({ S: "não" });
  const [enviarMsgClienteOperacao, setEnviarMsgClienteOperacao] = useState({ S: "não" });

  const [mostartAlerta] = useIonAlert();
  const [localEnderecoSelecionado, setLocalEnderecoSelecionado] = useState<any>("");
  const [listaEnderecos, setListaEnderecos] = useState<any>([]);


  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarConta();
    atualizarTamanhoImagem();
  });

  function carregarConta() {

    const consultar = async () => {

      console.log("aasasasasa", loja);
      let id = loja;

      requestService(empresaConfiguracoesCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaConfiguracoesCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          console.log(response);
          setEmpresaNome(response.Item.empresaNome || '');
          setEmpresaEndereco(response.Item.empresaEndereco || '');
          setEmpresaTelefone(response.Item.empresaTelefone || '');
          setEmpresaLatitude(response.Item.empresaLatitude || '');
          setEmpresaLongitude(response.Item.empresaLongitude || '');
          setUrlLogo(response.Item.urlLogo || '');
          setCondicoesGerais(response.Item.condicoesGerais || '');
          setNomeInternoMesaOuComanda(response.Item.nomeInternoMesaOuComanda || '');
          setNomeInternoMesasOuComandas(response.Item.nomeInternoMesasOuComandas || '');
          setImprimirMesaParcial(response.Item.imprimirMesaParcial || false);
          setServicoTipo(response.Item.servicoTipo || '');
          setServicoTipoPlural(response.Item.servicoTipoPlural || '');
          setNomeLinhaDoTempo(response.Item.nomeLinhaDoTempo || '');
          setTaxaServico(response.Item.taxaServico || '');
          setIdentificacaoPorNumero(response.Item.identificacaoPorNumero || false);
          setLayoutSimplificado(response.Item.layoutSimplificado || false);
          setZerarValoresVenda(response.Item.zerarValoresVenda || false);
          setEsconderValoresProjeto(response.Item.esconderValoresProjeto || false);
          setTipoAgendamentoPadrao(response.Item.tipoAgendamentoPadrao || '');
          setServicoPeriodoHoraEmpresa(response.Item.servicoPeriodoHoraEmpresa || false);
          setParametroMostrarHorario(response.Item.parametroMostrarHorario || false);
          setEnderecoPrincipalEmpresa(response.Item.enderecoPrincipalEmpresa || false);
          setEnviarGoogleAgendaCliente(response.Item.enviarGoogleAgendaCliente || false);
          setEnviarGoogleAgendaResponsavel(response.Item.enviarGoogleAgendaResponsavel || false);
          setInstrucoesMotorista(response.Item.instrucoesMotorista || '');

          setEnviarMsgClienteCadastro({ S: response.Item.enviarMsgClienteCadastro || "não" });
          setEnviarMsgClienteProjeto({ S: response.Item.enviarMsgClienteProjeto || "não" });
          setEnviarMsgResposavelProjeto({ S: response.Item.enviarMsgResposavelProjeto || "não" });
          setEnviarMsgClienteAgendamento({ S: response.Item.enviarMsgClienteAgendamento || "não" });
          setEnviarMsgResposavelAgendamento({ S: response.Item.enviarMsgResposavelAgendamento || "não" });
          setEnviarMsgResposavelOperacao({ S: response.Item.enviarMsgResposavelOperacao || "não" });
          setEnviarMsgClienteOperacao({ S: response.Item.enviarMsgClienteOperacao || "não" });

          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function atualizarTamanhoImagem() {
    var imagemLogo: any;
    imagemLogo = document.getElementById('imagemLogo');
    var img = new Image();
    img.onload = function () {
      var proporcao = img.width / img.height;
      var novaLargura = 250 * proporcao;
      imagemLogo.style.width = novaLargura + 'px';
    };
    img.src = imagemLogo.src;

  }


  const cadastrar = () => {
    setShowToast2(true);
  }


  const takePhoto = async (indice: string) => {

    const cameraPhoto = await Camera.getPhoto({
      resultType: CameraResultType.Base64,
      allowEditing: false,
      direction: CameraDirection.Rear,
      source: CameraSource.Prompt,
      promptLabelHeader: 'Fotos',
      promptLabelPhoto: 'Biblioteca de fotos',
      promptLabelPicture: 'Câmera',
      promptLabelCancel: 'Cancelar'
    });

    var binary_string = window.atob(cameraPhoto.base64String || '');
    var len = binary_string.length;
    var bytes = new Uint8Array(len);
    for (var i = 0; i < len; i++) {
      bytes[i] = binary_string.charCodeAt(i);
    }

    const blob = new Blob([new Uint8Array(bytes.buffer)], {
      type: `image/${cameraPhoto.format}`,
    });

    const file = new File([blob], "Name", {
      lastModified: new Date().getTime(),
      type: blob.type,
    });

    const options = {
      maxSizeMB: 0.5,
      maxWidthOrHeight: 1102,
      useWebWorker: true,
      initialQuality: 0.5
    }
    try {
      const compressedFile = await imageCompression(file, options);
      console.log('compressedFile instanceof Blob', compressedFile instanceof Blob); // true
      console.log(`compressedFile size ${compressedFile.size / 1024 / 1024} MB`); // smaller than maxSizeMB


      let base64 = await getBase64(compressedFile);
      await incluirFoto(indice, base64);

    } catch (error) {
      console.log(error);
    }

  };

  async function getBase64(file: any) {
    return new Promise((resolve) => {
      const reader = new FileReader()
      reader.onloadend = () => resolve(reader.result)
      reader.readAsDataURL(file)
    })
  }

  async function incluirFoto(indice: string, base64: any) {


    const atualizar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }


      let parametros = {
        base64: base64
      }

      try {
        setShowLoading(true);

        const resp = await fetch(empresaAlterar.url.replace("{id}", loja.replaceAll("#", "%23")).concat("?tipo=logoempresa"),
          {
            method: empresaAlterar.method,
            headers: [
              ["token", token]
            ],
            body: JSON.stringify(parametros),
            credentials: 'include'
          });




        const response = await resp.json();

        console.log("response", response);
        if (response.Item) {
          setUrlLogo(response.Item);
          atualizarTamanhoImagem();

        }
        setShowLoading(false);


      } catch (error) {
        console.log("erro", error);
        setShowLoading(false);
      }
    }

    atualizar();

  };

  function buscarLocalizacao(endereco: any) {
    if (endereco.replace(/\s/g, "") == "") return;

    let enderecoTratado = endereco.toLowerCase().replace(/\s/g, "+");


    requestService(buscarEndereco.url.replace("{id}", enderecoTratado), {

      method: buscarEndereco.method,
      headers: [
        ["token", window.localStorage.getItem("token")]
      ],
      credentials: 'include'
    },
      history,
      (response: any) => {
        if (response && response.Item) {
          try {
            setEmpresaLatitude(response.Item.enderecoLat);
            setEmpresaLongitude(response.Item.enderecoLng);

            setTextToast("Endereço validado com sucesso.");
            setShowToast(true);
          } catch (error) {
            console.log("error", error);
          }

        }
      },
      (error: any) => {
        console.log("e1", error);
        setTextToast(error.message);
        setShowToast(true);
        if (error.data) {
          console.log("entrou varios endereços");
          if (Array.isArray(error.data) && error.data.length > 0) {
            setListaEnderecos(error.data);
          }
        }
      }).finally(() => { setShowLoading(false); });
    ;
  }

  function readListaEnderecos() {
    return listaEnderecos.map((value: any, index: any) => (
      <IonItem
        key={"endereco" + value.place_id}
        button
        onClick={() => setLocalEnderecoSelecionado(value.formatted_address)}
        color={localEnderecoSelecionado === value.formatted_address ? 'tertiary' : ''}
      >
        <IonLabel>{value.formatted_address}</IonLabel>
      </IonItem>
    ));
  }

  return (
    <IonPage>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">


          <IonButtons slot="start">
            <IonButton onClick={() => { history.goBack(); }}><IonIcon slot="icon-only" icon={arrowBack} /></IonButton>
          </IonButtons>


          <IonTitle slot="start">Configurações</IonTitle>
        </IonToolbar>
      </IonHeader>
      <IonContent>
        <IonList>
          <IonItem lines="full">
            <IonLabel color="primary">Empresa</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee", "--border-radius": "5px", "--padding": "10px" }}
              rows={1}
              value={empresaNome}
              maxlength={100}
              placeholder="Nome da empresa"
              onIonInput={(e: any) => setEmpresaNome(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">
            <IonLabel position="stacked">Endereço</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={2}
              value={empresaEndereco}
              maxlength={200}
              placeholder="Endereço da empresa"
              onIonInput={(e: any) => setEmpresaEndereco(e.target.value)}
            ></IonTextarea>
            <div style={{ textAlign: 'right' }}>
              <IonButton onClick={() => { buscarLocalizacao(empresaEndereco); }}>Validar endereço</IonButton>
            </div>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Celular</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={empresaTelefone}
              maxlength={1300}
              placeholder="Telefone da empresa"
              onIonInput={(e: any) => setEmpresaTelefone(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para localização */}
            <IonLabel position="stacked">Latitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={empresaLatitude}
              maxlength={20}
              placeholder="Latitude"
              onIonInput={(e: any) => setEmpresaLatitude(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Longitude</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={empresaLongitude}
              maxlength={20}
              placeholder="Longitude"
              onIonInput={(e: any) => setEmpresaLongitude(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked" style={{ marginBottom: '10px' }}>Logo</IonLabel>

            <IonButtons slot="end" onClick={async () => { await takePhoto(loja).catch((e) => { console.log("cancelado"); }); }}>
              <IonIcon color="tertiary" size='large' slot="icon-only" icon={addCircle}></IonIcon>
            </IonButtons>
            <img id="imagemLogo" src={urlLogo} style={{ height: '250px' }} alt="logo" />
          </IonItem>


          {/* <IonItem lines="full">
            <IonLabel color="primary">Cadastro</IonLabel>
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteCadastro.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteCadastro({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem> */}


          <IonItem lines="full">
            <IonLabel color="primary">Vendas</IonLabel>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Condições Gerais</IonLabel>
            <IonTextarea

              style={{ "--background": "#eeeeee" }}
              rows={6}
              value={condicoesGerais}
              maxlength={1500}
              placeholder="Condições gerais para apresentar no rodapé do orçamento"
              onIonInput={(e: any) => setCondicoesGerais(e.target.value)}
            ></IonTextarea>
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
          </IonItem>


          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome Interno</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={nomeInternoMesaOuComanda}
              maxlength={50}
              placeholder="Nome Interno"
              onIonInput={(e: any) => setNomeInternoMesaOuComanda(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome Interno no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={nomeInternoMesasOuComandas}
              maxlength={50}
              placeholder="Nome Interno no Plural"
              onIonInput={(e: any) => setNomeInternoMesasOuComandas(e.target.value)}
            ></IonTextarea>
          </IonItem>

          <IonItem>
            <IonLabel>Imprimir Parcial</IonLabel>

            <IonToggle slot="end"
              checked={imprimirMesaParcial}
              onIonChange={(e: any) => setImprimirMesaParcial(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="full">
            <IonLabel color="primary">Processo</IonLabel>
          </IonItem>
          <IonItem lines="none">

            {/* Campos para serviços */}
            <IonLabel position="stacked">Nome</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={servicoTipo}
              maxlength={50}
              placeholder="Nome do processo"
              onIonInput={(e: any) => setServicoTipo(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome no Plural</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={servicoTipoPlural}
              maxlength={50}
              placeholder="Nome do processo no plural"
              onIonInput={(e: any) => setServicoTipoPlural(e.target.value)}
            ></IonTextarea>
          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Nome do Workflow</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={nomeLinhaDoTempo}
              maxlength={50}
              placeholder="Nome do Workflow"
              onIonInput={(e: any) => setNomeLinhaDoTempo(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">

            <IonLabel position="stacked">Taxa</IonLabel>
            <IonTextarea
              style={{ "--background": "#eeeeee" }}
              rows={1}
              value={taxaServico}
              maxlength={50}
              placeholder="% - Taxa de Serviço"
              onIonInput={(e: any) => setTaxaServico(e.target.value)}
            ></IonTextarea>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Identificação por Número</IonLabel>

            <IonToggle slot="end"
              checked={identificacaoPorNumero}
              onIonChange={(e: any) => setIdentificacaoPorNumero(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Layout Simplificado (sem Workflow)</IonLabel>

            <IonToggle slot="end"
              checked={layoutSimplificado}
              onIonChange={(e: any) => setLayoutSimplificado(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">

            {/* Campos para os valores específicos */}
            <IonLabel>Zerar valores da venda ao criar o Processo</IonLabel>

            <IonToggle slot="end"
              checked={zerarValoresVenda}
              onIonChange={(e: any) => setZerarValoresVenda(e.detail.checked)}
            />

          </IonItem>

          <IonItem lines="none">
            <IonLabel>Esconder Valores</IonLabel>

            <IonToggle slot="end"
              checked={esconderValoresProjeto}
              onIonChange={(e: any) => setEsconderValoresProjeto(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelProjeto.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelProjeto({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="full">
            <IonLabel color="primary">Agenda</IonLabel>
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Tipo de Agendamento Padrão</IonLabel>
            <IonSelect placeholder="Selecione" slot="end" value={tipoAgendamentoPadrao} onIonChange={(e) => { setTipoAgendamentoPadrao(e.detail.value || "") }}>
              <IonSelectOption value="entrega">entrega</IonSelectOption>
              <IonSelectOption value="serviço">serviço</IonSelectOption>
              <IonSelectOption value="reunião">reunião</IonSelectOption>
              <IonSelectOption value="consulta">consulta</IonSelectOption>
              <IonSelectOption value="visita técnica">visita técnica</IonSelectOption>
              <IonSelectOption value="reserva">reserva</IonSelectOption>
              <IonSelectOption value="check in">check in</IonSelectOption>
              <IonSelectOption value="check out">check out</IonSelectOption>
            </IonSelect>

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Habilitar Período</IonLabel>

            <IonToggle slot="end"
              checked={servicoPeriodoHoraEmpresa}
              onIonChange={(e: any) => setServicoPeriodoHoraEmpresa(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none">
            <IonLabel>Mostrar horário (sim) / período (não)</IonLabel>
            <IonToggle slot="end"
              checked={parametroMostrarHorario}
              onIonChange={(e: any) => setParametroMostrarHorario(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            <IonLabel>Endereço empresa (sim) / cliente (não)</IonLabel>
            <IonToggle slot="end"
              checked={enderecoPrincipalEmpresa}
              onIonChange={(e: any) => setEnderecoPrincipalEmpresa(e.detail.checked)}
            />

          </IonItem>
          <IonItem lines="none">
            <IonLabel>Enviar Google Agenda Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaCliente}
              onIonChange={(e: any) => setEnviarGoogleAgendaCliente(e.detail.checked)}
            />
          </IonItem>
          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            <IonLabel>Enviar Google Agenda Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarGoogleAgendaResponsavel}
              onIonChange={(e: any) => setEnviarGoogleAgendaResponsavel(e.detail.checked)}
            />
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>

          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelAgendamento.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelAgendamento({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>



          <IonItem lines="full">
            <IonLabel color="primary">Operação</IonLabel>
          </IonItem>

          <IonItem lines="none">
            {/* Enviar mensagem para o Cliente */}
            <IonLabel>Enviar mensagem para o Cliente</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgClienteOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgClienteOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


          <IonItem lines="none" hidden={(window.localStorage.getItem("servicoPrincipal") != "Obras")}>
            {/* Enviar mensagem ao responsável */}
            <IonLabel>Enviar mensagem para o Arquiteto</IonLabel>
            <IonToggle slot="end"
              checked={enviarMsgResposavelOperacao.S === "sim"}
              onIonChange={(e: any) => setEnviarMsgResposavelOperacao({ S: e.detail.checked ? "sim" : "não" })}
            />
          </IonItem>


















        </IonList>
        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonAlert isOpen={showToast2}
          onDidDismiss={() => setShowToast2(false)}
          header={'Alteração de cadastro'}
          message={'Você <strong>confirma</strong> a alteração?'}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {

                setShowLoading(true);

                const objectRequest = {
                  empresaNome,
                  empresaEndereco,
                  empresaTelefone,
                  empresaLatitude,
                  empresaLongitude,
                  urlLogo,
                  condicoesGerais,
                  nomeInternoMesaOuComanda,
                  nomeInternoMesasOuComandas,
                  imprimirMesaParcial,
                  servicoTipo,
                  servicoTipoPlural,
                  nomeLinhaDoTempo,
                  taxaServico,
                  identificacaoPorNumero,
                  layoutSimplificado,
                  zerarValoresVenda,
                  esconderValoresProjeto,
                  tipoAgendamentoPadrao,
                  servicoPeriodoHoraEmpresa,
                  parametroMostrarHorario,
                  enderecoPrincipalEmpresa,
                  enviarGoogleAgendaCliente,
                  enviarGoogleAgendaResponsavel,
                  instrucoesMotorista,
                  enviarMsgClienteCadastro: enviarMsgClienteCadastro.S,
                  enviarMsgClienteProjeto: enviarMsgClienteProjeto.S,
                  enviarMsgResposavelProjeto: enviarMsgResposavelProjeto.S,
                  enviarMsgClienteAgendamento: enviarMsgClienteAgendamento.S,
                  enviarMsgResposavelAgendamento: enviarMsgResposavelAgendamento.S,
                  enviarMsgResposavelOperacao: enviarMsgResposavelOperacao.S,
                  enviarMsgClienteOperacao: enviarMsgClienteOperacao.S
                };
                let id = loja;

                console.log("id", id)

                requestService(
                  empresaAlterar.url.replace("{id}", id.replaceAll("#", "%23")).concat("?tipo=configuracoesempresa"),
                  {
                    method: empresaAlterar.method,
                    body: JSON.stringify(objectRequest),
                    headers: [
                      ["token", window.localStorage.getItem("token")]
                    ],
                    credentials: 'include'
                  },
                  history,
                  (response: any) => {
                    console.log(response);
                    if (response) {
                      setTextToast("Alteração realizado com sucesso.");
                      setShowSucesso(true);

                    } else if (response.status === 400) {
                      console.log(response);
                      setTextToast("Ocorreu um erro ao efetuar a alteração.");
                      setShowToast(true);

                    }
                    setShowLoading(false);
                  },
                  (error: any) => {
                    console.log(error);
                    setTextToast("Ocorreu um erro ao efetuar a alteração.");
                    setShowToast(true);
                    setShowLoading(false);
                  })
              }
            }
          ]}
        />

        <IonModal isOpen={(Array.isArray(listaEnderecos) && listaEnderecos.length > 0)} onDidDismiss={() => { setListaEnderecos([]); }}>
          <IonHeader>
            <IonToolbar color="primary">
              <IonTitle>Selecione um endereço</IonTitle>
              <IonButtons slot="start" onClick={() => setListaEnderecos([])}>
                <IonIcon slot="icon-only" icon={arrowBack}></IonIcon>
              </IonButtons>
            </IonToolbar>
          </IonHeader>
          <IonContent className="ion-padding">
            <IonList>
              {readListaEnderecos()}
            </IonList>
          </IonContent>
          <IonFooter>
            <IonButton expand="block" color="primary" onClick={(e) => {

              mostartAlerta({
                backdropDismiss: false,
                header: 'Número e complemento',
                message: 'Adicione Número e complemento',
                inputs: [
                  {
                    name: 'mensagem',
                    type: 'textarea',
                    id: 'name2-id',
                    placeholder: 'Digite aqui o número e complemento',
                  }],
                buttons: ['Cancel', {
                  text: 'OK', handler: (d) => {
                    setEmpresaEndereco(localEnderecoSelecionado + ", " + d.mensagem);
                    buscarLocalizacao(localEnderecoSelecionado + ", " + d.mensagem);
                  }
                }],
                onDidDismiss: (e) => { console.log('did dismiss'); }
              })

              setListaEnderecos([]);
            }}>Avançar</IonButton>
          </IonFooter>
        </IonModal>

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showSucesso}
          onDidDismiss={(e: any) => { setShowSucesso(false); history.goBack(); }}
          message={'Alterações salvas com sucesso!'}
          duration={3000}
          animated={false}
        />
      </IonContent>
      <IonFooter className="ion-no-border">
        <IonToolbar>
          <IonButton onClick={() => cadastrar()} expand="block" type="submit" hidden={acessos?.configuracoes != 'edicao'}>Salvar</IonButton>
        </IonToolbar>
      </IonFooter>
    </IonPage>
  );
};

export default ContaAlt;
