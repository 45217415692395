import { Toast } from '@capacitor/toast';
import { IonAlert, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonSelect, IonSelectOption, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { add, arrowBack } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { excluiUsuario, listaEquipe, listaEquipePorPerfil, requestService, resetSenhaUsuario, usuariosAlterarAcesso } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import EquipeCad from '../EquipeCad/EquipeCad';
import './Equipe.css';

type Props = {
  abrirMenu: boolean;
}

const Equipe: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [chamadaRealizada, setChamadaRealizada] = useState(false);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [perfil, setPerfil] = useState(localStorage.getItem("perfil"));
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cpf, setCpf] = useState("");
  const [showResetSenha, setShowResetSenha] = useState(false);
  const [showExcluirUsuario, setShowExcluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState<string>();
  const [usuario, setUsuario] = useState<any>();
  const [stateAcessos, setStateAcessos] = useState<any>({ projetos: false, operacoes: false, chat: false, vendas: false });

  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);

  const inputDetalhesRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarUsuarios()

  });



  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  function carregarUsuarios(perfil?: any) {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let resp: any;
      let perfil = "usuario";

      console.log("perfil", perfil);
      if (!perfil) {
        resp = await fetch(listaEquipe.url,
          {
            method: listaEquipe.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
      }
      else {
        resp = await fetch(listaEquipePorPerfil.url.replace("{perfil}", perfil),
          {
            method: listaEquipePorPerfil.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
      }
      await resp.json().then((res: any) => {
        if (resp.status === 400 || resp.status === 401) {
          history.replace("/login/");
        }
        else {

          // Inclui acessos para usuários que ainda não têm estas informações no banco de dados
          let resAcessos = res.Items.map((item: any) => {
            if (item.acessos) {
              return item;
            }
            else {
              return item;
            }
          }).sort((a: any, b: any) => (a.nome.toLowerCase() > b.nome.toLowerCase()) ? 1 : -1);

          setArrayOperacoes(resAcessos);
          if (resAcessos.LastEvaluatedKey) {
            setOperacaopk(resAcessos.LastEvaluatedKey.pk);
            setDesabilitarInfiniteScroll(false);
          }
          else {
            setOperacaopk("");
            setDesabilitarInfiniteScroll(true);
          }
        }
      })
    }

    consultar();
  }

  async function carregarMaisUsuarios($event: CustomEvent<void>) {

    await carregarMaisUsuariosChamada().then(() => {
      console.log("terminou resultados");
    });

    ($event.target as HTMLIonInfiniteScrollElement).complete();


  }

  async function carregarMaisUsuariosChamada() {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    const res: Response = await fetch(listaEquipe.url + "?pk=" + operacaopk,
      {
        method: listaEquipe.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        console.log(arrayOperacoes.length);
        for (let i = 0; i < operacoes.Items.length; i++) {
          console.log("resultados");
          arrayOperacoes.push(operacoes.Items[i]);
        }
        console.log(arrayOperacoes.length);
        if (operacoes.LastEvaluatedKey) {
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
          console.log("finalizou");
        }
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");
  }

  async function closeModal() {
    setShowDetalhes(false);
    carregarUsuarios();
  }

  async function closeModalSimple() {
    setShowDetalhes(false);
  }

  function doRefresh(event: any) {
    carregarUsuarios();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  function alterarAcesso(item: any, index: any, valor: any) {
    let objCopia = JSON.stringify(arrayOperacoes);
    let obj = [...arrayOperacoes];

    let objetoNovo = obj[index];
    if (objetoNovo.acessos) {
      objetoNovo.acessos[item] = valor;
    }
    else {
      objetoNovo.acessos = { [item]: valor }
    }

    obj[index] = objetoNovo;

    alterarUsuarioAcesso(objetoNovo)
      .then((resp: any) => { Toast.show({ text: 'Atualizado com sucesso' }) })
      .catch((err: any) => { setArrayOperacoes(JSON.parse(objCopia)); Toast.show({ text: 'Erro ao tentar atualizar' }) });
  }

  function alterarUsuarioAcesso(objeto: any) {
    return fetch(usuariosAlterarAcesso.url.replace("{pk}", btoa(objeto.pk)),
      {
        method: usuariosAlterarAcesso.method,
        headers: [["token", localStorage.getItem("token") || ""]],
        credentials: 'include',
        body: JSON.stringify(objeto)
      });
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>Usuários</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary" hidden={perfil === "usuario"} >



          <IonSearchbar value={pesquisa} onIonClear={(e) => setPesquisa(undefined)} onIonChange={(e) => { setPesquisa(e.detail.value?.toLocaleLowerCase()) }} placeholder='Nome do usuário' ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="equipecontent" fullscreen >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonButton hidden={true} onClick={() => { setUsuario(null); setShowDetalhes(true) }}>Novo</IonButton>
        <IonFab hidden={perfil === "usuario" || (acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao')} vertical="bottom" horizontal="center" slot="fixed">
          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setUsuario(null); setShowDetalhes(true) }} >
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>
        </IonFab>

        <IonCard key="primeira" hidden={!(arrayOperacoes === undefined || arrayOperacoes.length === 0)}>
          <IonCardHeader id="primeira" >
            <IonCardTitle>Aqui aparecem os usuários da equipe</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>

        {arrayOperacoes.map((value: any, index: any, arr: any) =>

          <IonCard key={Math.random()} className="equipeCard" hidden={(!pesquisa || pesquisa === "" || value.nomeMinusculo?.indexOf(pesquisa.toString()) > -1) ? false : true} >
            <IonCardHeader id={value.pk} style={{ "background": value.color }}>
              <IonCardTitle>{value.nome}</IonCardTitle>
            </IonCardHeader>
            <IonCardContent>
              <IonGrid>
                <IonRow>
                  <IonCol size='4' sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='4' sizeXl='4'>
                    <div className="equipe">
                      <IonLabel position="fixed">Perfil</IonLabel>
                      <IonLabel className="equipeTexto">{value.perfil == "empresa" ? "administrador" : value.perfil}</IonLabel>
                    </div>
                    <div className="equipe">
                      <IonLabel>Documento</IonLabel>
                      <IonLabel className="equipeTexto">{cpf_mask(value.cpf)}</IonLabel>
                    </div>
                    <div className="equipe">
                      <IonLabel>E-mail</IonLabel>
                      <IonLabel className="equipeTexto">{value.email}</IonLabel>
                    </div>
                    <div className="equipe">
                      <IonLabel>Telefone</IonLabel>
                      <IonLabel className="equipeTexto">{value.telefone}</IonLabel>
                    </div>
                    <div className="equipe" hidden={!value.endereco}>
                      <IonLabel>Endereço</IonLabel>
                      <IonLabel className="equipeTexto">{value.endereco}</IonLabel>
                    </div>
                    <div className="equipe" hidden={!value.dataAniversario}>
                      <IonLabel>Aniversário</IonLabel>
                      <IonLabel className="equipeTexto">{
                        value.dataAniversario ?
                          new Intl.DateTimeFormat('pt-BR', { timeZone: 'UTC' }).format(
                            Date.parse(value.dataAniversario)
                          ) : ""
                      }</IonLabel>
                    </div>

                    <div className="equipe" hidden={!value.statusUsuario}>
                      <IonLabel>Status</IonLabel>
                      <IonLabel className="equipeTexto">{value.statusUsuario}</IonLabel>
                    </div>

                    <div className="equipe" >
                      <IonLabel>Loja</IonLabel>

                      {value.lojas?.map((valorLoja: any, indiceLoja: any, arrLoja: any) => (
                        <>
                          {indiceLoja == 0 ? valorLoja.empresaLojaNome : ", " + valorLoja.empresaLojaNome}
                        </>
                      ))}
                    </div>

                  </IonCol>
                  <IonCol size='4' sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='4' sizeXl='4'>
                    <div className="equipe">
                      <IonLabel>Acessos módulos</IonLabel>
                    </div>

                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Vendas</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.vendas || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('vendas', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>{(window.localStorage.getItem("servicoTipoPlural") || "Projetos")}</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.projetos || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('projetos', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>{(window.localStorage.getItem("nomeInternoBalcaoOuCaixa") || "Balcão")}</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.caixa || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('caixa', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>{(window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas")}</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.comandas || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('comandas', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Agenda</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.agenda || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('agenda', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Operações</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.operacoes || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('operacoes', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    {/* <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Whatsapp</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.chat || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('chat', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div> */}

                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Financeiro</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.financeiro || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('financeiro', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>

                  </IonCol>
                  <IonCol size='4' sizeXs='12' sizeSm='12' sizeMd='6' sizeLg='4' sizeXl='4'>
                    <div className="equipe">
                      <IonLabel>Acessos cofigurações</IonLabel>
                    </div>

                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Cadastros</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.cadastros || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('cadastros', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Lembretes</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.lembretes || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('lembretes', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    {/* <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Campanha</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.campanha || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('campanha', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div> */}
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Relatórios</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.relatorios || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('relatorios', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>
                    <div className="equipe">
                      <IonLabel className="equipeTexto" style={{ display: 'flex', margin: 'auto 0px auto 0px', padding: '0' }}>Configurações</IonLabel>
                      <IonSelect style={{ padding: '0' }} value={value?.acessos?.configuracoes || 'sem acesso'} interface='popover' onIonChange={(e) => { alterarAcesso('configuracoes', index, e.detail.value); }}>
                        <IonSelectOption value="sem acesso">Sem acesso</IonSelectOption>
                        <IonSelectOption value="leitura">Leitura</IonSelectOption>
                        <IonSelectOption value="edicao">Edição</IonSelectOption>
                      </IonSelect>
                    </div>

                  </IonCol>

                </IonRow>
              </IonGrid>
              <IonItem lines="none">
                <IonButton size="small" fill='outline' shape="round" color="tertiary" slot="start" disabled={perfil == "usuario" || value.cpf == localStorage.getItem("cpf") || (acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao')} onClick={() => { setUsuario(value); setShowDetalhes(true) }}>Alterar</IonButton>
                <IonButton size="small" fill='solid' shape="round" color="tertiary" slot="end" className="botaoExcluir" disabled={perfil == "usuario" || value.cpf == localStorage.getItem("cpf") || (acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao')} onClick={() => { console.log(value.pk); setCpf(value.pk); setMensagem("Deseja realmente excluir a conta do " + value.nome + "?"); setShowExcluirUsuario(true) }}>Excluir conta</IonButton>
              </IonItem>
              <IonLoading
                cssClass='my-custom-class'
                isOpen={showLoading}
                onDidDismiss={() => { setShowLoading(false); }}
                message={'Registrando...'}

              />
            </IonCardContent>

          </IonCard>

        )}

        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisUsuarios(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonAlert
          isOpen={showResetSenha}
          onDidDismiss={() => setShowResetSenha(false)}
          header={'Reset de Senha'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(resetSenhaUsuario.url.replace("{cpf}", cpf), {
                  method: resetSenhaUsuario.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showExcluirUsuario}
          onDidDismiss={() => setShowExcluirUsuario(false)}
          header={'Exclusão de Usuário'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(excluiUsuario.url.replace("{cpf}", cpf.replaceAll(/#/g, "%23")), {
                  method: excluiUsuario.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    carregarUsuarios();
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />

        <IonModal onDidPresent={() => {
          if (inputDetalhesRef.current) {
            inputDetalhesRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); }}>
          <EquipeCad usuario={usuario} inputRef={inputDetalhesRef} closeAction={closeModal} closeActionSimple={closeModalSimple}></EquipeCad>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default Equipe;
