import { IonButton, IonButtons, IonCard, IonCardContent, IonChip, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRow, IonTitle, IonToast, IonToolbar, useIonAlert, useIonViewDidEnter } from '@ionic/react';
import { Buffer } from 'buffer';
import { addCircle, arrowBack, arrowDown, arrowUp, pricetagOutline, printOutline, removeCircle, trash } from 'ionicons/icons';
import React, { createRef, useContext, useEffect, useRef, useState } from 'react';
import { useHistory, useLocation, useParams } from 'react-router';
import { alterarStatusProjeto, empresaCarregar, enviarMesa, mesaGerarCupom, requestService, urlBase } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import CaixaFechar from '../CaixaFechar/CaixaFechar';
import Desconto from '../Desconto/Desconto';
import ProjetosProdutosComporNovoMemory from '../ProjetosProdutosComporNovoMemory/ProjetosProdutosComporNovoMemory';
import "./CaixaProdutosMemory.css";

type Props = {
  id: any;
  nome: any;
};

const CaixaProdutosMemory: React.FC<Props> = (props: Props) => {

  // Hooks de navegação
  const history = useHistory<any>();
  const location = useLocation<any>();

  // Estados relacionados a loading e feedback visual
  const [showLoading, setShowLoading] = useState(false);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");

  // Estados de modais e alertas
  const [showCaixaFechar, setShowCaixaFechar] = useState(false);
  const [showModalDesconto, setShowModalDesconto] = useState(false);
  const [mostrarAlerta] = useIonAlert();

  // Estados de controle de exibição
  const [stateShowMenu, setStateShowMenu] = useState(!true);
  const [stateShowCaixa, setStateShowCaixa] = useState(!true);
  const [showHabilitarEdicao, setShowHabilitarEdicao] = useState(false);

  // Estados de dados e listas
  const [listaItemProdutos, setListaItemProdutos] = useState<any>([]);
  const [dadosCaixa, setDadosCaixa] = useState<any>([]);
  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();

  // Contextos compartilhados
  const { loja, setLoja } = useContext(MessageContext);
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);
  // const [dadosEmpresa, setDadosEmpresa] = useState<any>([]); // Comentei pois já está no context

  // Estados persistidos no localStorage
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

  // Referências
  const buscarInputRef = useRef<any>(null);
  const contentCatalogoRef = useRef<any>(null);
  const contentRef = createRef<HTMLIonContentElement>();

  // Funções de rolagem
  const scrollToTop = () => {
    if (contentRef.current) {
      contentRef.current.scrollToTop();
    }
    setStateShowMenu(!true);
    setStateShowCaixa(!false);
  };

  const scrollToBottom = () => {
    if (contentRef.current) {
      contentRef.current.scrollToBottom();
    }
    setStateShowMenu(!false);
    setStateShowCaixa(!true);
  };

  const scrollToCatalogo = () => {
    if (contentCatalogoRef.current) {
      contentCatalogoRef.current.scrollIntoView();
    }
  };

  let params: any = {
    id: ""
  }
  params = useParams();

  if (location && location.state && location.state.id) {
    params.id = location.state.id;
  }

  if (props.id) {
    params = {
      id: props.id,
    }
  }

  useEffect(() => {
    if (params.id) {
      console.log("entrou no useEffect");
      carregarCaixa();
      carregarEmpresa();
    }
  }, [params.id]);

  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(dadosCaixa.taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(dadosCaixa.desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(dadosCaixa.taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (dadosCaixa.taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (dadosCaixa.desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (dadosCaixa.taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [valorTotalProdutos]);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
  });

  function carregarEmpresa() {

    const consultar = async () => {

      requestService(empresaCarregar.url.replace("{id}", loja.replaceAll(/#/g, '%23')).concat("?filtro=", Buffer.from(loja).toString("base64")), {
        method: empresaCarregar.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        credentials: 'include'
      },
        history,
        (response: any) => {
          setDadosEmpresa(response.Item);
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          history.replace("/login/");
        });

    }

    consultar();
  }

  function carregarCaixa() {

    const consultar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + params.id.replaceAll(/#/g, "%23") + "/semassinar",
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();
      if (response.Item !== undefined) {

        setDadosCaixa(response.Item);
        setListaItemProdutos(response.Item.produtos || []);

        setValorTotalProdutos((response.Item.valorTotalProdutos || 0).toLocaleString('pt-br', {
          minimumFractionDigits: 2,
          maximumFractionDigits: 2
        }));

      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }

  function deletarItemProdutos(index: number) {
    // Filtra a lista de produtos, removendo o item com o índice fornecido
    const updatedList = listaItemProdutos.filter((_: any, i: any) => i !== index);

    // Atualiza a lista de produtos com o item removido
    setListaItemProdutos(updatedList);

    // Calcula a nova soma dos valores após a remoção
    const totalValor = updatedList.reduce((total: any, item: any) => {
      const itemValorNumerico = parseFloat(
        item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
      );
      return total + itemValorNumerico;
    }, 0);

    // Exemplo: você pode armazenar a nova soma em outro estado ou usar conforme necessário
    console.log("Nova soma dos valores:", totalValor);

    setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }));

  }

  async function adicionarItem(produto: any) {

    if (produto) {

      let updatedList = [...listaItemProdutos, produto];

      setListaItemProdutos(updatedList);


      let totalValor = updatedList.reduce((total: any, item: any) => {
        const itemValorNumerico = parseFloat(
          item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
        );
        return total + itemValorNumerico;
      }, 0);

      setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

      setTextToast("Item adicionado com sucesso.");
      setShowToast(true);

    }
    scrollToCatalogo();
  }

  async function salvar(marcarComoEnviado: any = false) {

    return new Promise<void>(async (resolve, reject) => {

      let objRequest = {
        "produtos": listaItemProdutos,
        "enviado": marcarComoEnviado

      };

      requestService(enviarMesa.url.replaceAll("{id}", params.id.replaceAll(/#/g, '%23')), {
        method: enviarMesa.method,
        headers: [
          ["token", window.localStorage.getItem("token")]
        ],
        body: JSON.stringify(objRequest),
        credentials: 'include'
      },
        history,
        async (response: any) => {


          if (response.message && response.message.Attributes) {

            setDadosCaixa(response.message.Attributes);
            setListaItemProdutos(response.message.Attributes.produtos || []);

            setValorTotalProdutos((response.message.Attributes.valorTotalProdutos || 0).toLocaleString('pt-br', {
              minimumFractionDigits: 2,
              maximumFractionDigits: 2
            }));

            resolve(response.pk);

          }
          else {
            reject(new Error("Erro: Resposta inválida ou incompleta."));
          }
        },
        (error: any) => {
          console.log(error);
          reject(error);  // Promessa rejeitada com erro
        });
    });
  }

  async function alterar(
    formaPagamento: any,
    taxaServico: any,
    taxaEntrega: any,
    desconto: any
  ) {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        formaPagamento: formaPagamento, //formaPagamento,
        // troco: calcularValorTroco(valorTotal, arrayFormaPagamento),
        taxaEntrega: taxaEntrega,
        taxaServico: taxaServico,
        desconto: desconto,
      };

      requestService(
        alterarStatusProjeto.url.replaceAll("{id}", params.id.replaceAll(/#/g, "%23")) + "?alteracao=true",
        {
          method: alterarStatusProjeto.method,
          headers: [["token", localStorage.getItem("token") || ""]],
          credentials: "include",
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          // console.log("response", response);
          setDadosCaixa(response);
          setValorTotal((response.valorTotal || 0).toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
          resolve(response); // Resolve a Promise se a alteração for bem-sucedida
        },
        (error: any) => {
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise se houver erro
        }
      );
    });
  }

  async function imprimir() {
    return new Promise<void>((resolve, reject) => {
      gerarCupomMesa(params.id)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function gerarCupomMesa(projetoid: any) {
    return new Promise(async (resolve, reject) => {
      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof tkn === "string") {
        token = tkn;
      }
      console.log("projetoid", projetoid);

      let montaUrl = mesaGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));

      try {
        const resp = await fetch(montaUrl, {
          method: mesaGerarCupom.method,
          headers: [["token", token]],
          credentials: "include",
        });

        const result = await resp.json();
        if (result) {
          // console.log("result", result);

          mostrarAlerta({
            backdropDismiss: false,
            header: "Gerar Cupom",
            message: "<strong>Cupom</strong> foi gerado com sucesso.",
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
              },
              {
                text: "Visualizar",
                cssClass: "primary",
                handler: () => {
                  window.open(result, "_blank");
                },
              },
            ],
            onDidDismiss: (e) => console.log("did dismiss"),
          });
          resolve(result); // Resolve a Promise se a impressão for bem-sucedida
        }
      } catch (error: any) {
        setTextToast(error.message);
        setShowToast(true);
        reject(error); // Rejeita a Promise em caso de erro
      }
    });
  }

  async function closeModalCaixaFechar(formaPagamento: any) {
    setShowCaixaFechar(false);
    await alterar(formaPagamento, dadosCaixa.taxaServico, dadosCaixa.taxaEntrega, dadosCaixa.desconto);
  }

  async function closeModalCaixaFecharSucess() {
    setShowCaixaFechar(false);
    history.goBack();
  }

  async function closeModalDesconto() {
    setShowModalDesconto(false);
  }

  async function closeModalDescontoSucess(desconto: any) {
    setShowModalDesconto(false);
    await alterar(dadosCaixa.formaPagamento, dadosCaixa.taxaServico, dadosCaixa.taxaEntrega, desconto);
  }

  const loadItemProdutos = () => {

    if (listaItemProdutos == undefined) return;

    if (listaItemProdutos.length === 0) {
      return (
        <>
          <IonItem lines="none">

            <IonLabel>
              <h2 style={{ "verticalAlign": "middle" }}><strong>Nenhum item cadastrado</strong></h2>
            </IonLabel>

          </IonItem>
        </>

      );
    }

    return (
      <>
        {/* The reorder gesture is disabled by default, enable it to drag and drop items */}
        <IonGrid>

          {listaItemProdutos.map((value: any, index: any) => (
            <>


              {value.enviado ? (
                <div style={{ position: 'relative' }}>
                  <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                    <IonChip

                      style={{
                        height: "20px",
                        backgroundColor: 'var(--ion-color-medium)',
                        color: '#fff',
                        fontWeight: 'bold',

                      }}
                    >
                      ENVIADO
                    </IonChip>
                  </div>
                </div>
              ) :
                value.salvo ? (
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                      <IonChip

                        style={{
                          height: "20px",
                          backgroundColor: 'var(--ion-color-primary)',
                          color: '#fff',
                          fontWeight: 'bold',

                        }}
                      >
                        SALVO
                      </IonChip>
                    </div>
                  </div>
                ) : (
                  <div style={{ position: 'relative' }}>
                    <div style={{ position: 'absolute', top: '4px', right: '0px', textAlign: 'right' }}>
                      <IonChip

                        style={{
                          height: "20px",
                          backgroundColor: 'var(--ion-color-success)',
                          color: '#fff',
                          fontWeight: 'bold',

                        }}
                      >
                        NOVO
                      </IonChip>
                    </div>
                  </div>
                )

              }

              <IonRow style={{ borderColor: '#dddddd', borderWidth: '1px', borderBottomStyle: 'dashed' }}>


                <IonCol size="9">



                  {(value.descricao && Array.isArray(value.descricao)) ? (

                    <>
                      {(value.tipo === "pizzas") ? (
                        <div>{value.tamanho} {value.quantidadeSabores} SABORES</div>
                      ) : (
                        <div>{value.categoria}</div>
                      )}
                      {value.descricao.map((item: any, index: number) => (
                        <div key={"desc" + index}>
                          - {item.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {item.valor?.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          })}</IonLabel>)
                        </div>
                      ))}
                    </>

                  ) : (
                    <div key={"desc" + index}>
                      {value.descricao} (<IonLabel style={{ textAlign: "right", fontSize: "12px" }}>R$ {value.valor?.toLocaleString('pt-br', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      })}</IonLabel>)

                    </div>
                  )}



                </IonCol>


                <IonCol style={{ display: 'flex', alignItems: 'flex-end', justifyContent: 'flex-end' }} sizeXs="3" >
                  <IonLabel style={{ textAlign: "right" }}>{value.subtotal}</IonLabel>
                </IonCol>
                <IonCol sizeSm='6'>
                  <IonLabel style={{ textAlign: "right", fontSize: "12px" }} >
                    <IonChip style={{ margin: 0, padding: '5px' }} disabled={!showHabilitarEdicao && value.enviado}>
                      <IonIcon size='large' color='danger' icon={removeCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata a quantidade atual
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        if (quantidadeFormatada > 1) {
                          // Decrementa a quantidade
                          let quantidadeNova = quantidadeFormatada - 1;

                          // Formata o valor e calcula o novo valor com base na nova quantidade
                          const valorFormatado = parseFloat(
                            itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );

                          // Atualiza a quantidade e o valor do item
                          itemToUpdate.quantidade = quantidadeNova;
                          const valorFloat = quantidadeNova * valorFormatado;

                          itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          });

                          // Substitui o item atualizado na lista copiada
                          updatedList[index] = itemToUpdate;

                          let totalValor = updatedList.reduce((total: any, item: any) => {
                            const itemValorNumerico = parseFloat(
                              item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                            );
                            return total + itemValorNumerico;
                          }, 0);

                          setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                            minimumFractionDigits: 2,
                            maximumFractionDigits: 2
                          }));

                          // Atualiza o estado com a nova lista
                          setListaItemProdutos(updatedList);

                        }

                      }}></IonIcon>
                      <IonLabel style={{ fontSize: '20px' }}>{value.quantidade.toLocaleString('pt-br', {
                        minimumFractionDigits: 0,
                        maximumFractionDigits: 0
                      })}</IonLabel>
                      <IonIcon size='large' color='success' icon={addCircle} onClick={() => {

                        // Faz uma cópia da lista atual de produtos
                        let updatedList = [...listaItemProdutos];

                        // Acessa o item que será atualizado
                        let itemToUpdate = { ...updatedList[index] };

                        // Formata e incrementa a quantidade
                        const quantidadeFormatada = parseFloat(
                          itemToUpdate.quantidade.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        let quantidadeNova = quantidadeFormatada + 1;

                        // Formata o valor e calcula o novo valor com base na nova quantidade
                        const valorFormatado = parseFloat(
                          itemToUpdate.valor.toString().replace(/\./g, '').replace(/\,/g, '.')
                        );

                        // Atualiza a quantidade e o valor do item
                        itemToUpdate.quantidade = quantidadeNova;
                        const valorFloat = quantidadeNova * valorFormatado;

                        itemToUpdate.subtotal = valorFloat.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        });

                        // Substitui o item atualizado na lista copiada
                        updatedList[index] = itemToUpdate;

                        let totalValor = updatedList.reduce((total: any, item: any) => {
                          const itemValorNumerico = parseFloat(
                            item.subtotal.toString().replace(/\./g, '').replace(/\,/g, '.')
                          );
                          return total + itemValorNumerico;
                        }, 0);

                        setValorTotalProdutos(totalValor.toLocaleString('pt-br', {
                          minimumFractionDigits: 2,
                          maximumFractionDigits: 2
                        }));

                        // Atualiza o estado com a nova lista
                        setListaItemProdutos(updatedList);


                      }}></IonIcon>
                    </IonChip>
                  </IonLabel>
                </IonCol>
                <IonCol style={{ display: "flex", justifyContent: "flex-end" }} sizeSm='6'>
                  <IonButtons>
                    <IonButton disabled={!showHabilitarEdicao && value.enviado}
                      onClick={() => {
                        mostrarAlerta({
                          backdropDismiss: false,
                          header: 'Remover item',
                          message: 'Deseja <strong>remover</strong>?',
                          buttons: [
                            {
                              text: 'Cancelar',
                              role: 'cancel',
                            },
                            {
                              text: 'Confirmar',
                              cssClass: 'primary',
                              handler: () => {
                                deletarItemProdutos(index);
                              }
                            }
                          ],
                          onDidDismiss: (e) => console.log('did dismiss'),
                        });
                      }}
                    >
                      <IonIcon color="primary" icon={trash} />
                    </IonButton>
                  </IonButtons>
                </IonCol>

              </IonRow>
            </>
          ))}
        </IonGrid>
      </>
    );
  }

  return (

    <IonPage className="CaixaProdutosMemory">
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {



              if (listaItemProdutos.filter((produto: any) => (!produto.salvo)).length > 0) {
                mostrarAlerta({
                  backdropDismiss: false,
                  header: 'Descartar novos itens',
                  message: "Itens novos adicionados. Deseja descartar?",

                  buttons: [{
                    text: 'Cancelar',
                    role: 'cancel'
                  },
                  {
                    text: 'Confirmar',
                    handler: async () => {
                      history.goBack();
                    }
                  }],
                  onDidDismiss: () => { console.log('did dismiss'); }
                })

              }
              else { history.goBack(); }

            }
            } />
          </IonButtons>
          <IonTitle>{dadosCaixa?.clienteNome}</IonTitle>
        </IonToolbar>




      </IonHeader>

      <IonContent ref={contentRef} fullscreen={false}>
        <IonGrid style={{ height: '100%', padding: "0px", margin: "0px" }} ref={contentCatalogoRef}>
          <IonRow style={{ height: '100%' }}>

            <IonCol hidden={stateShowMenu} style={{ height: '100%', padding: "0px", margin: "0px" }} sizeMd="8" size="12">
              <div style={{ height: '100%', maxHeight: '100%', overflow: 'auto' }}>
                <IonFab vertical='bottom' horizontal='end' style={{ "bottom": "20px", "right": "20px" }} className='visible-mobile'>
                  <IonFabButton onClick={() => scrollToBottom()} color='tertiary'>
                    <IonIcon icon={arrowDown}></IonIcon>
                  </IonFabButton>
                </IonFab>
                <ProjetosProdutosComporNovoMemory
                  empresa={dadosEmpresa}
                  disabled={false}
                  adicionarItem={adicionarItem}
                />
              </div>
            </IonCol>


            <IonCol hidden={stateShowCaixa} style={{ height: '100%', padding: "0px", margin: "0px" }} sizeMd="4" size="12">
              <div style={{ height: '100%', padding: '10px' }}>
                <IonFab vertical='top' horizontal='end' style={{ "top": "20px", "right": "20px" }} className='visible-mobile'>
                  <IonFabButton onClick={() => scrollToTop()} color='tertiary'>
                    <IonIcon icon={arrowUp}></IonIcon>
                  </IonFabButton>
                </IonFab>
                <IonCard style={{ margin: 0, height: '100%' }}>
                  <IonCardContent style={{ height: '100%' }}>

                    <IonGrid style={{ padding: "0px", margin: "0px" }}>

                      <IonRow style={{ borderColor: 'grey', fontSize: '25px', borderWidth: '1px', borderBottomStyle: 'solid' }}>
                        <IonCol style={{ padding: "0px", margin: "0px" }} size="12"><IonLabel><b>Lista de itens</b></IonLabel></IonCol>
                      </IonRow>

                    </IonGrid>

                    <div style={{ height: '100%', position: 'relative', overflow: 'auto' }}>
                      <div style={{
                        height: 'calc(100% - 240px)',
                        overflow: 'auto'
                      }}>
                        {loadItemProdutos()}
                      </div>
                    </div>
                    <div style={{ position: 'absolute', bottom: "10px", left: 0, right: 0, padding: '0 10px' }}>
                      <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>

                        {/* Resumo subtotal, desconto, taxa de serviço, total geral */}
                        <IonGrid style={{ padding: "4px", margin: "4px" }}>

                          <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel><b>Subtotal</b></IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotalProdutos ? "R$ " + valorTotalProdutos : "R$ 0,00"}</b></IonLabel></IonCol>

                          </IonRow>

                          <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel>Desconto</IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel style={{ display: 'block', textAlign: "right" }}>{dadosCaixa.desconto ? "R$ " + dadosCaixa.desconto : "R$ 0,00"}</IonLabel></IonCol>

                          </IonRow>

                          <IonRow style={{ fontSize: '16px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel>Taxa de serviço</IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel style={{ display: 'block', textAlign: "right" }}>{(dadosCaixa.taxaServico || 0) + "%"}</IonLabel></IonCol>

                          </IonRow>

                          <IonRow style={{ fontSize: '20px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="5"><IonLabel><b>Total</b></IonLabel></IonCol>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="7"><IonLabel style={{ display: 'block', textAlign: "right" }}><b>{valorTotal ? "R$ " + valorTotal : "R$ 0,00"}</b></IonLabel></IonCol>

                          </IonRow>

                        </IonGrid>

                        <IonGrid style={{ padding: "0px", margin: "0px" }} hidden={showHabilitarEdicao}>
                          <IonRow style={{ fontSize: '20px' }}>
                            <IonCol style={{ padding: "0px", margin: "0px" }} size="6">
                              <IonButton color='success'
                                style={{ height: "46px", fontSize: "18px" }}
                                onClick={async () => {
                                  setShowLoading(true);

                                  salvar()
                                    .then((response) => {
                                      console.log("Alteração bem-sucedida", response);
                                      return imprimir(); // Aguarda a execução de imprimir após alterar
                                    })
                                    .then(() => {
                                      console.log("Impressão bem-sucedida");
                                    })
                                    .catch((error) => {
                                      console.log("Erro:", error.message);
                                      setTextToast(error.message);
                                      setShowToast(true);
                                    })
                                    .finally(() => {
                                      setShowLoading(false);
                                    });
                                }}
                                expand="block"
                                type="submit"
                              >
                                <IonIcon icon={printOutline}></IonIcon>
                              </IonButton>
                            </IonCol>

                            <IonCol style={{ padding: "0px", margin: "0px" }} size="6">
                              <IonButton color='primary'
                                style={{ height: "46px", fontSize: "18px", fontWeight: 'bold' }}
                                onClick={async () => {
                                  setShowLoading(true);

                                  salvar()
                                    .then((response) => {
                                      console.log("Alteração bem-sucedida", response);
                                      setShowModalDesconto(true);
                                    })
                                    .catch((error) => {
                                      console.log("Erro:", error.message);
                                      setTextToast(error.message);
                                      setShowToast(true);
                                    })
                                    .finally(() => {
                                      setShowLoading(false);
                                    });
                                }}
                                expand="block"
                                type="submit"
                              >
                                <IonIcon icon={pricetagOutline}></IonIcon>
                              </IonButton>
                            </IonCol>

                          </IonRow>
                        </IonGrid>

                        <IonButton hidden={!showHabilitarEdicao}
                          style={{ height: "46px", fontSize: "18px" }}
                          onClick={async () => {

                            await salvar();
                            setShowHabilitarEdicao(false);
                          }}
                          expand="block"
                          type="submit"
                        >
                          Salvar
                        </IonButton>

                        <IonButton hidden={showHabilitarEdicao}
                          style={{ height: "46px", fontSize: "18px" }}
                          onClick={async () => {
                            setShowLoading(true);

                            salvar()
                              .then((response) => {
                                console.log("Alteração bem-sucedida", response);
                                setShowCaixaFechar(true);
                              })
                              .catch((error) => {
                                console.log("Erro:", error.message);
                                setTextToast(error.message);
                                setShowToast(true);
                              })
                              .finally(() => {
                                setShowLoading(false);
                              });



                          }}
                          expand="block"
                          type="submit"
                        >
                          Fechar {(window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão")}
                        </IonButton>
                      </div>
                    </div>


                  </IonCardContent>
                </IonCard>
              </div>
            </IonCol>

          </IonRow>
        </IonGrid>

        <IonModal isOpen={showCaixaFechar} onDidDismiss={() => { setShowCaixaFechar(false); }}>
          <CaixaFechar projetoid={params.id} closeAction={closeModalCaixaFecharSucess} closeActionSimple={closeModalCaixaFechar}></CaixaFechar>
        </IonModal>

        <IonModal isOpen={showModalDesconto} onDidDismiss={() => { closeModalDesconto(); }}>
          <Desconto projetoid={params.id} closeAction={closeModalDescontoSucess} closeActionSimple={closeModalDesconto}></Desconto>
        </IonModal>


        <IonToast
          isOpen={showToast}
          position="middle" //top, bottom, middle
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />

      </IonContent>
    </IonPage >
  );


};

export default CaixaProdutosMemory;