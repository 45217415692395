import { IonButton, IonButtons, IonCol, IonContent, IonFooter, IonGrid, IonHeader, IonIcon, IonInput, IonLabel, IonLoading, IonRow, IonTitle, IonToast, IonToolbar, useIonAlert } from '@ionic/react';
import { arrowBack, printOutline, trashOutline } from 'ionicons/icons';
import React, { useContext, useEffect, useRef, useState } from 'react';
import { useHistory } from 'react-router';
import { MessageContext } from '../../../../../contexts/MessageContext';
import { alterarStatusProjeto, fecharMesa, mesaGerarCupom, requestService, urlBase } from '../../../../../Utils/Services';
import "./CaixaFechar.css";


type Props = {
  projetoid: string;
  closeAction: Function;
  closeActionSimple: Function;
};

const CaixaFechar: React.FC<Props> = (props: Props) => {

  const history = useHistory<any>();
  const [showLoading, setShowLoading] = useState(false);
  const [dadosProjeto, setDadosProjeto] = useState<any>([]);
  const [showToast, setShowToast] = useState(false);
  const [textToast, setTextToast] = useState("");
  const buscarInputRef = useRef<any>(null);
  const [taxaEntrega, setTaxaEntrega] = useState<any>();
  // const [formaPagamento, setFormaPagamento] = useState('');

  const { dadosEmpresa, setDadosEmpresa } = useContext(MessageContext);

  const [mostrarAlerta] = useIonAlert();


  const [divididoPor, setDivididoPor] = useState<any>(); // Array para armazenar campos dinâmicos
  const [arrayFormaPagamento, setArrayFormaPagamento] = useState<string[]>([]); // Array para armazenar campos dinâmicos

  const opcoesPagamento = [
    'Dinheiro',
    'Cartão de Crédito',
    'Cartão de Débito',
    'Pix',
    'Alelo',
    'Sodexo',
    'Vale Alimentação',
    'Boleto'
  ];

  const [desconto, setDesconto] = useState('');

  const opcoesDesconto = [
    { label: 'R$ 0,00', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 15,00', value: '15,00' },
    { label: 'R$ 20,00', value: '20,00' },
    { label: 'R$ 25,00', value: '25,00' }
  ];

  const [taxaServico, setTaxaServico] = useState('');

  const opcoesTaxaEntrega = [
    { label: 'Grátis', value: '0,00' },
    { label: 'R$ 5,00', value: '5,00' },
    { label: 'R$ 6,00', value: '6,00' },
    { label: 'R$ 7,00', value: '7,00' },
    { label: 'R$ 8,00', value: '8,00' },
    { label: 'R$ 10,00', value: '10,00' },
    { label: 'R$ 12,00', value: '12,00' },
    { label: 'R$ 15,00', value: '15,00' },
  ];

  const [valorTotalProdutos, setValorTotalProdutos] = useState<any>();
  const [valorTotal, setValorTotal] = useState<any>();

  useEffect(() => {
    if (props.projetoid != "") {
      console.log("entrou no useEffect");
      carregarProjeto();
      //alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
    }
  }, []);


  useEffect(() => {

    try {
      let taxaServicoFloat = parseFloat(taxaServico?.replace(/\./g, '').replace(/\,/g, '.'));
      let descontoFloat = parseFloat(desconto?.replace(/\./g, '').replace(/\,/g, '.'));
      let taxaEntregaFloat = parseFloat(taxaEntrega?.replace(/\./g, '').replace(/\,/g, '.'));

      let valorTotalRecalculado = 0;
      if (valorTotalProdutos) {
        valorTotalRecalculado = parseFloat(valorTotalProdutos.replace(/\./g, '').replace(/\,/g, '.'));
      }

      if (taxaEntrega) {
        valorTotalRecalculado += (taxaEntregaFloat ? taxaEntregaFloat : 0);
      }

      if (desconto) {
        valorTotalRecalculado += - (descontoFloat ? descontoFloat : 0);
      }
      if (taxaServico) {
        valorTotalRecalculado += (valorTotalRecalculado * (taxaServicoFloat / 100));
      }

      setValorTotal(valorTotalRecalculado.toLocaleString('pt-BR', {
        minimumFractionDigits: 2,
        maximumFractionDigits: 2
      }));

    } catch (error) {
      console.log("erro ao calcular", error);
    }
  }, [taxaEntrega, taxaServico, desconto, valorTotalProdutos]);



  // useEffect(() => {
  //   if (formaPagamento) {
  //     alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  //   }
  // }, [formaPagamento]);



  // useEffect(() => {
  //   if (desconto) {
  //     alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  //   }
  // }, [desconto]);



  function carregarProjeto() {

    const consultar = async () => {

      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") { token = tkn; }
      setShowLoading(true);
      const resp = await fetch(urlBase.url + "/projetos/" + props.projetoid.replaceAll(/#/g, '%23'),
        {
          method: urlBase.method,
          headers: [
            ["token", token]
          ],
          credentials: 'include'
        }).finally(() => {
          setShowLoading(false);
        });
      const response = await resp.json();

      if (response.Item) {

        if (response.Item.formaPagamento && Array.isArray(response.Item.formaPagamento)) {
          setArrayFormaPagamento([...response.Item.formaPagamento]);
        }


        setDesconto(response.Item.desconto);
        if (response.Item.valorTotalProdutos) {
          setValorTotalProdutos(response.Item.valorTotalProdutos?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
        else {
          setValorTotalProdutos("0,00");
        }

        if (response.Item.valorTotal) {
          setValorTotal(response.Item.valorTotal?.toLocaleString('pt-br', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          }));
        }
        else {
          setValorTotal("0,00");
        }
      }

      if (buscarInputRef.current) {
        buscarInputRef.current.setFocus();
      }
    }

    consultar();
  }


  // function preencherProjeto(response: any) {

  //   console.log("response", response);

  //   setDadosProjeto(response.Item || []);

  //   if (response.formaPagamento && Array.isArray(response.formaPagamento)) {
  //     setArrayFormaPagamento([...response.formaPagamento]);
  //   }


  //   setTaxaServico(response.taxaServico);
  //   setDesconto(response.desconto);
  //   setTaxaEntrega(response.taxaEntrega);

  //   if (response.valorTotalProdutos) {
  //     setValorTotalProdutos(response.valorTotalProdutos?.toLocaleString('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }));
  //   }
  //   else {
  //     setValorTotalProdutos("0,00");
  //   }

  //   if (response.valorTotal) {
  //     setValorTotal(response.valorTotal?.toLocaleString('pt-br', {
  //       minimumFractionDigits: 2,
  //       maximumFractionDigits: 2
  //     }));
  //   }
  //   else {
  //     setValorTotal("0,00");
  //   }

  // }

  function fechar() {
    return new Promise((resolve, reject) => {
      setShowLoading(true);

      requestService(
        fecharMesa.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")),
        {
          method: fecharMesa.method,
          headers: [["token", window.localStorage.getItem("token")]],
          credentials: "include",
        },
        history,
        async (response: any) => {
          if (response) {
            // atualiza estoque
            if (response.data) {
              setDadosEmpresa(response.data);
            }

            // history.goBack();
            resolve(response); // Resolve a Promise se a mesa for fechada com sucesso
          }
          setShowLoading(false);
        },
        (error: any) => {
          console.log(error);
          setShowLoading(false);
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise em caso de erro
        }
      );
    });
  }




  async function alterar(
    // formaPagamento: any,
    // taxaServico: any,
    // taxaEntrega: any,
    // desconto: any
  ) {
    return new Promise((resolve, reject) => {
      const objectRequest = {
        formaPagamento: arrayFormaPagamento, //formaPagamento,
        troco: calcularValorTroco(valorTotal, arrayFormaPagamento),
        taxaEntrega: taxaEntrega,
        taxaServico: taxaServico,
        desconto: desconto,
      };

      requestService(
        alterarStatusProjeto.url.replaceAll("{id}", props.projetoid.replaceAll(/#/g, "%23")) + "?alteracao=true",
        {
          method: alterarStatusProjeto.method,
          headers: [["token", localStorage.getItem("token") || ""]],
          credentials: "include",
          body: JSON.stringify(objectRequest),
        },
        history,
        (response: any) => {
          console.log("response", response);
          // preencherProjeto(response);
          resolve(response); // Resolve a Promise se a alteração for bem-sucedida
        },
        (error: any) => {
          setTextToast(error.message);
          setShowToast(true);
          reject(error); // Rejeita a Promise se houver erro
        }
      );
    });
  }

  async function imprimir() {
    return new Promise<void>((resolve, reject) => {
      gerarCupomMesa(props.projetoid)
        .then(() => {
          resolve();
        })
        .catch((error) => {
          reject(error);
        });
    });
  }

  async function gerarCupomMesa(projetoid: any) {
    return new Promise(async (resolve, reject) => {
      var token: string = "";
      var tkn = localStorage.getItem("token");
      if (typeof tkn === "string") {
        token = tkn;
      }
      console.log("projetoid", projetoid);

      setShowLoading(true);
      let montaUrl = mesaGerarCupom.url.replaceAll("{id}", projetoid.replaceAll(/#/g, "%23"));

      try {
        const resp = await fetch(montaUrl, {
          method: mesaGerarCupom.method,
          headers: [["token", token]],
          credentials: "include",
        });

        const result = await resp.json();
        if (result) {
          console.log("result", result);

          mostrarAlerta({
            backdropDismiss: false,
            header: "Gerar Cupom",
            message: "<strong>Cupom</strong> foi gerado com sucesso.",
            buttons: [
              {
                text: "Cancelar",
                role: "cancel",
              },
              {
                text: "Visualizar",
                cssClass: "primary",
                handler: () => {
                  window.open(result, "_blank");
                },
              },
            ],
            onDidDismiss: (e) => console.log("did dismiss"),
          });
          resolve(result); // Resolve a Promise se a impressão for bem-sucedida
        }
      } catch (error: any) {
        setTextToast(error.message);
        setShowToast(true);
        reject(error); // Rejeita a Promise em caso de erro
      } finally {
        setShowLoading(false);
      }
    });
  }

  const onChangeValorArray = (e: any, index: number) => {
    // e.persist();
    e.preventDefault();

    let v = e.target.value;

    v = v.replace(/\d{9}/, '');
    v = v.replace(/\D/g, '');
    v = v.replace(/(\d{1})(\d{1,2})$/, "$1,$2");
    v = v.replace(/(\d)(?=(\d{3})+(?!\d))/g, '$1.');
    v = v.replace(/^(\d)/g, "$1");





    const novosCampos: any = [...arrayFormaPagamento];
    novosCampos[index].valor = v;
    setArrayFormaPagamento(novosCampos);

  };


  function onChangeQuantidadeDivididoPor(e: any) {

    // e.persist();
    e.preventDefault();

    let v = e.target.value;
    v = v.replace(/\D/g, '');

    console.log("v", v);

    setDivididoPor(v);
  }


  // Função para adicionar formas de pagamento
  const handleAdicionarFormaPagamento = (opcao: string) => {
    const valorTotalNumerico = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.'));
    const somaValores = arrayFormaPagamento.reduce((total: any, campo: any) => total + (parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0), 0);
    const valorRestante = valorTotalNumerico - somaValores;
    console.log("somaValores", somaValores);
    console.log("valorTotalNumerico", valorTotalNumerico);
    console.log("valorRestante", valorRestante);

    if (valorRestante > 0) {
      // Adiciona a nova forma de pagamento com valor inicial 0
      setArrayFormaPagamento((prevCampos: any) => [
        ...prevCampos,
        {
          pagamento: opcao, valor: valorRestante.toLocaleString('pt-BR', {
            minimumFractionDigits: 2,
            maximumFractionDigits: 2
          })
        } // Pode começar com valor 0
      ]);
    } else {
      console.log('Valor total atingido. Não é possível adicionar mais formas de pagamento.');
    }
  };

  const handleRemoverFormaPagamento = (index: number) => {
    setArrayFormaPagamento((prevCampos: any) => prevCampos.filter((_: any, i: any) => i !== index));
    // alterar(formaPagamento, taxaServico, taxaEntrega, desconto);
  };

  function calcularValorRestante(valorTotal: string, arrayFormaPagamento: any[]): any {
    const totalPagamentos = arrayFormaPagamento.reduce((total: number, campo: any) => {
      return total + parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;
    }, 0);

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    // Calcula o valor restante e garante que não seja negativo
    const valorRestante = Math.max(0, valorTotalFloat - totalPagamentos);

    // Retorna o valor formatado
    return valorRestante;
  }

  function calcularValorTroco(valorTotal: string, arrayFormaPagamento: any[]): string {
    const totalPagamentos = arrayFormaPagamento.reduce((total: number, campo: any) => {
      return total + parseFloat(campo.valor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;
    }, 0);

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    // Calcula o valor restante, garantindo que não seja negativo
    const valorTroco = Math.max(0, totalPagamentos - valorTotalFloat);

    // Formata o valor restante para o padrão brasileiro (duas casas decimais, vírgula e ponto)
    return valorTroco.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    });
  }

  function calcularDivisao(valorTotal: string, divididoPor: any): string {

    const valorTotalFloat = parseFloat(valorTotal?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    const divididoPorFloat = parseFloat(divididoPor?.replace(/\./g, '').replace(/\,/g, '.')) || 0;

    console.log(valorTotalFloat / divididoPorFloat);

    // Calcula o valor restante, garantindo que não seja negativo
    const valorDividido = Math.max(0, valorTotalFloat / divididoPorFloat);

    // Formata o valor restante para o padrão brasileiro (duas casas decimais, vírgula e ponto)
    return valorDividido.toLocaleString('pt-BR', {
      minimumFractionDigits: 2,
      maximumFractionDigits: 2
    }) + " pra cada";
  }

  return (

    <>
      <IonHeader className="ion-no-border">
        <IonToolbar color="primary">

          <IonButtons slot="start">
            <IonIcon slot="icon-only" icon={arrowBack} onClick={() => {
              props.closeActionSimple(arrayFormaPagamento);
            }} />
          </IonButtons>
          <IonTitle>Fechamento</IonTitle>

        </IonToolbar>


      </IonHeader>

      <IonContent fullscreen={false}>

        <IonGrid>

          {/* <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
            <IonCol size="6" style={{ display: 'flex', alignItems: 'center' }}>
              <IonLabel>Desconto:</IonLabel>
            </IonCol>
            <IonCol size="6" style={{ textAlign: 'right' }}>
              <IonSelect
                placeholder="Selecione"
                value={desconto}
                onIonChange={(e) => {
                  setDesconto(e.detail.value); // Atualiza o valor do desconto
                }}
              >
                {opcoesDesconto.map((opcao, index) => (
                  <IonSelectOption key={index} value={opcao.value}>
                    {opcao.label}
                  </IonSelectOption>
                ))}
              </IonSelect>
            </IonCol>

          </IonRow> */}

          <IonRow>
            <IonCol size="12" style={{}}>
              <IonLabel>Forma de pagamento:</IonLabel>
            </IonCol>

          </IonRow>

          <IonRow>

            {opcoesPagamento.map((opcao, index) => (
              <IonCol size="auto" key={index}> {/* size="6" coloca 2 botões por linha */}
                <IonButton
                  style={{ height: "30px", fontSize: "12px", margin: "0px" }}
                  expand="block"
                  shape="round"
                  onClick={() => handleAdicionarFormaPagamento(opcao)}
                >
                  {opcao.toUpperCase()}
                </IonButton>
              </IonCol>
            ))}
          </IonRow>


        </IonGrid>




        <IonToast
          isOpen={showToast}
          position="middle"
          onDidDismiss={() => setShowToast(false)}
          message={textToast}
          duration={3000}
        />

        <IonLoading
          cssClass='my-custom-class'
          isOpen={showLoading}
          onDidDismiss={() => { setShowLoading(false); }}
          spinner='circles'
        />
      </IonContent>
      <IonFooter>

        <IonToolbar>


          <IonGrid>





            {/* <IonRow>
              <IonCol size="5" sizeMd='4'>
                <IonLabel>Dividir a conta por:</IonLabel>
              </IonCol>
              <IonCol size="2" style={{ textAlign: 'left' }}>
                <IonInput
                  style={{
                    "--background": "#eeeeee",
                    "--padding-start": "10px", // Aplica o padding à esquerda
                    "--padding-end": "10px",   // Aplica o padding à direita
                    height: "20px",            // Ajusta a altura para que o padding tenha efeito
                    borderRadius: "20px",      // Arredonda as bordas
                    boxSizing: "border-box",   // Garante que o padding seja considerado no tamanho total
                  }}
                  type="tel"
                  maxlength={2}
                  placeholder=""
                  value={divididoPor}
                  onIonChange={(e: any) => onChangeQuantidadeDivididoPor(e)}
                />

              </IonCol>
              <IonCol size="5" sizeMd='6' style={{ textAlign: 'right' }}>
                <IonLabel>R$ {calcularDivisao(valorTotal, divididoPor)}

                </IonLabel>
              </IonCol>
            </IonRow> */}







            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="12" style={{
                margin: "0px",
                padding: "0px"

              }}>
                <IonGrid style={{
                  margin: "0px",
                  padding: "0px"

                }}>

                  {/* Renderiza dinamicamente campos com base nas seleções */}
                  {arrayFormaPagamento.map((campo: any, index: any) => (
                    <div key={index}>
                      <IonRow>
                        <IonCol size="7" sizeMd="8">
                          <IonLabel>{campo.pagamento}:</IonLabel>
                        </IonCol>
                        <IonCol size="4" sizeMd="3" style={{ textAlign: 'right' }}>
                          <IonInput
                            clearInput={true}
                            style={{
                              "--background": "#eeeeee",
                              "--padding-start": "10px", // Aplica o padding à esquerda
                              "--padding-end": "10px",   // Aplica o padding à direita
                              height: "20px",            // Ajusta a altura para que o padding tenha efeito
                              borderRadius: "20px",      // Arredonda as bordas
                              boxSizing: "border-box",   // Garante que o padding seja considerado no tamanho total
                            }}
                            type="tel"
                            maxlength={14}
                            placeholder=""
                            value={campo.valor || ''}
                            onIonChange={(e: any) => onChangeValorArray(e, index)} // Continua monitorando as mudanças no valor
                          // onBlur={() => alterar(formaPagamento, taxaServico, taxaEntrega, desconto)} // Chama a função alterar ao perder o foco
                          />
                        </IonCol>

                        <IonCol size="1" style={{ textAlign: 'center' }}>
                          <IonIcon
                            icon={trashOutline}
                            style={{ cursor: 'pointer', color: 'red' }}
                            onClick={() => handleRemoverFormaPagamento(index)}
                          />
                        </IonCol>
                      </IonRow>
                    </div>
                  ))}
                </IonGrid>

              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6">
                <IonLabel>Restante:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>
                <IonLabel>R$ {calcularValorRestante(valorTotal, arrayFormaPagamento).toLocaleString('pt-BR', {
                  minimumFractionDigits: 2,
                  maximumFractionDigits: 2
                })}</IonLabel>
              </IonCol>
            </IonRow>


            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="6">
                <IonLabel>Troco:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>

                <IonLabel>R$ {calcularValorTroco(valorTotal, arrayFormaPagamento)}

                </IonLabel>
              </IonCol>
            </IonRow>








            <IonRow>
              <IonCol size="6" style={{ fontWeight: 'bold' }}>
                <IonLabel>Subtotal:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right', fontWeight: 'bold' }}>
                <IonLabel>R$ {valorTotalProdutos || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow style={{ "borderBottom": "1px solid #e0e0e0" }}>
              <IonCol size="6" style={{}}>
                <IonLabel>Desconto:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right' }}>
                <IonLabel>R$ {desconto || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>

            <IonRow>
              <IonCol size="6" style={{ fontWeight: 'bold', fontSize: "20px" }}>
                <IonLabel>Valor Total:</IonLabel>
              </IonCol>
              <IonCol size="6" style={{ textAlign: 'right', fontWeight: 'bold', fontSize: "20px" }}>
                <IonLabel>R$ {valorTotal || "0,00"}</IonLabel>
              </IonCol>
            </IonRow>
            <IonRow>
              <IonCol sizeMd="2" size="4">
                <IonButton color="success" style={{ height: "46px", fontSize: "18px" }} expand="block"
                  type="submit"
                  onClick={async () => {


                    // let valorRestante = calcularValorRestante(valorTotal, arrayFormaPagamento);

                    // if (valorRestante <= 0) {


                    setShowLoading(true);

                    alterar()
                      .then((response) => {
                        console.log("Alteração bem-sucedida", response);
                        return imprimir(); // Aguarda a execução de imprimir após alterar
                      })
                      .then(() => {
                        console.log("Impressão bem-sucedida");
                      })
                      .catch((error) => {
                        console.log("Erro:", error.message);
                        setTextToast(error.message);
                        setShowToast(true);
                      })
                      .finally(() => {
                        setShowLoading(false);
                      });
                    // }
                    // else {
                    //   setTextToast("Ainda falta R$ " + valorRestante.toLocaleString('pt-BR', {
                    //     minimumFractionDigits: 2,
                    //     maximumFractionDigits: 2
                    //   }) + " para imprimir. Selecione a forma de pagamento.");
                    //   setShowToast(true);
                    // }
                  }}
                >
                  <IonIcon icon={printOutline}></IonIcon>
                </IonButton>
              </IonCol>
              <IonCol sizeMd="10" size="8">
                <IonButton style={{ height: "46px", fontSize: "18px" }} expand="block"
                  type="submit"
                  onClick={async () => {

                    let valorRestante = calcularValorRestante(valorTotal, arrayFormaPagamento);

                    if (valorRestante <= 0) {
                      mostrarAlerta({
                        backdropDismiss: false,
                        header: 'Fechar balcão',
                        message: "Confirma fechamento?",
                        buttons: [{
                          text: 'Cancelar',
                          role: 'cancel'
                        },
                        {
                          text: 'Confirmar',
                          handler: async () => {


                            setShowLoading(true);

                            alterar()
                              .then((response) => {
                                console.log("Alteração bem-sucedida", response);
                                return fechar(); // Aguarda a execução de fechar após alterar
                              })
                              .then(() => {
                                props.closeAction();
                                console.log("Mesa fechada com sucesso");
                              })
                              .catch((error) => {
                                console.log("Erro:", error.message);
                                setTextToast(error.message);
                                setShowToast(true);
                              })
                              .finally(() => {
                                setShowLoading(false);
                              });


                          }
                        }],
                        onDidDismiss: () => { console.log('did dismiss'); }

                      })
                    } else {
                      setTextToast("Ainda falta R$ " + valorRestante.toLocaleString('pt-BR', {
                        minimumFractionDigits: 2,
                        maximumFractionDigits: 2
                      }) + " para fechar. Selecione a forma de pagamento.");
                      setShowToast(true);
                    }

                  }}
                >
                  Fechar {(window.localStorage.getItem("nomeInternonomeInternoBalcaoOuCaixa") || "Balcão")}
                </IonButton>
              </IonCol>
            </IonRow>
          </IonGrid>




        </IonToolbar>
      </IonFooter >
    </>


  );
};

export default ({ projetoid, closeAction, closeActionSimple }: { projetoid: any, closeAction: Function, closeActionSimple: Function }) => (
  <CaixaFechar projetoid={projetoid} closeAction={closeAction} closeActionSimple={closeActionSimple}>
  </CaixaFechar>
)
