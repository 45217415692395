import {
    IonAlert,
    IonContent,
    IonFooter,
    IonIcon,
    IonItem,
    IonLabel,
    IonList,
    IonMenu,
    IonMenuToggle,
    IonNote,
    IonSelect,
    IonSelectOption,
    isPlatform
} from '@ionic/react';
import { alertCircleOutline, closeOutline, createOutline, exitOutline, gridOutline, lockClosedOutline, logoUsd, readerOutline, settingsOutline } from 'ionicons/icons';
import { useContext, useState } from 'react';
import { useHistory, useLocation } from 'react-router';
import { deslogar, empresaCarregar, requestService, usuarioExcluir } from '../../../../Utils/Services';
import { MessageContext } from '../../../../contexts/MessageContext';
import './Menu.css';

interface AppPage {
    url: string;
    iosIcon: string;
    title: string;
    hidden: boolean;
}

interface TypeaheadProps {
    id: string;
}

function Menu(props: TypeaheadProps) {


    const history = useHistory();
    const location = useLocation();
    const [showToast2, setShowToast2] = useState(false);
    const [showToast3, setShowToast3] = useState(false);
    const { loja, setLoja } = useContext(MessageContext);

    const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));

    const appPages: AppPage[] = [
        {
            title: 'Painel',
            url: 'painel',
            iosIcon: gridOutline,
            hidden: false
        },
        {
            title: 'Cadastros',
            url: 'menucadastros',
            iosIcon: createOutline,
            hidden: acessos?.cadastros != 'leitura' && acessos?.cadastros != 'edicao',
        },
        {
            title: 'Lembretes',
            url: 'lembretes',
            iosIcon: alertCircleOutline,
            hidden: acessos?.lembretes != 'leitura' && acessos?.lembretes != 'edicao',
        },
        // {
        //     title: 'Campanha',
        //     url: 'menucampanha',
        //     iosIcon: notificationsOutline,
        //     hidden: acessos?.campanha != 'leitura' && acessos?.campanha != 'edicao'
        // },
        {
            title: 'Financeiro',
            url: 'financeiro',
            iosIcon: logoUsd,
            hidden: acessos?.financeiro != 'leitura' && acessos?.financeiro != 'edicao'
        },
        {
            title: 'Relatórios',
            url: 'menurelatorios',
            iosIcon: readerOutline,
            hidden: acessos?.relatorios != 'leitura' && acessos?.relatorios != 'edicao'
        },
        {
            title: 'Configurações',
            url: 'menuconfiguracoes',
            iosIcon: settingsOutline,
            hidden: acessos?.configuracoes != 'leitura' && acessos?.configuracoes != 'edicao'
        }

    ];

    function carregarConfigWorkflowEmpresa(loja?: any) {
        console.log("entrou configs")
        const consultar = async () => {

            let id = loja;

            requestService(empresaCarregar.url.replace("{id}", id.replaceAll(/#/g, '%23')), {
                method: empresaCarregar.method,
                headers: [
                    ["token", window.localStorage.getItem("token")]
                ],
                credentials: 'include'
            },
                history,
                (response: any) => {
                    if (response.Item.configWorkflow) {
                        window.localStorage.setItem("configWorkflow", JSON.stringify(response.Item.configWorkflow));
                    } else {
                        window.localStorage.removeItem("configWorkflow");
                    }
                    if (response.Item.servicoPrincipal) { window.localStorage.setItem("servicoPrincipal", response.Item.servicoPrincipal); } else { window.localStorage.removeItem("servicoPrincipal"); }
                    if (response.Item.identificacaoPorNumero) { window.localStorage.setItem("identificacaoPorNumero", response.Item.identificacaoPorNumero); } else { window.localStorage.removeItem("identificacaoPorNumero"); }
                    if (response.Item.servicoTipo) { window.localStorage.setItem("servicoTipo", response.Item.servicoTipo); } else { window.localStorage.removeItem("servicoTipo"); }
                    if (response.Item.servicoTipoPlural) { window.localStorage.setItem("servicoTipoPlural", response.Item.servicoTipoPlural); } else { window.localStorage.removeItem("servicoTipoPlural"); }
                    if (response.Item.nomeLinhaDoTempo) { window.localStorage.setItem("nomeLinhaDoTempo", response.Item.nomeLinhaDoTempo); } else { window.localStorage.removeItem("nomeLinhaDoTempo"); }
                    if (response.Item.polos) { window.localStorage.setItem("polos", response.Item.polos); } else { window.localStorage.removeItem("polos"); }
                    if (response.Item.naoAgrupar) { window.localStorage.setItem("naoAgrupar", response.Item.naoAgrupar); } else { window.localStorage.removeItem("naoAgrupar"); }
                    if (response.Item.naoOperacao) { window.localStorage.setItem("naoOperacao", response.Item.naoOperacao); } else { window.localStorage.removeItem("naoOperacao"); }
                    if (response.Item.esconderValoresProjeto) { window.localStorage.setItem("esconderValoresProjeto", response.Item.esconderValoresProjeto); } else { window.localStorage.removeItem("esconderValoresProjeto"); }

                    if (response.Item.nomeInternoMesaOuComanda) { window.localStorage.setItem("nomeInternoMesaOuComanda", response.Item.nomeInternoMesaOuComanda); } else { window.localStorage.removeItem("nomeInternoMesaOuComanda"); }
                    if (response.Item.nomeInternoMesasOuComandas) { window.localStorage.setItem("nomeInternoMesasOuComandas", response.Item.nomeInternoMesasOuComandas); } else { window.localStorage.removeItem("nomeInternoMesasOuComandas"); }
                    if (response.Item.imprimirMesaParcial) { window.localStorage.setItem("imprimirMesaParcial", response.Item.imprimirMesaParcial); } else { window.localStorage.removeItem("imprimirMesaParcial"); }
          

                    if (response.Item.configWorkflowVenda) {
                        window.localStorage.setItem("configWorkflowVenda", JSON.stringify(response.Item.configWorkflowVenda));
                    } else {
                        window.localStorage.removeItem("configWorkflowVenda");
                    }

                    if (response.Item.empresaMarca) { window.localStorage.setItem("empresaMarca", response.Item.empresaMarca); } else { window.localStorage.removeItem("empresaMarca"); }
                    if (response.Item.parceria) { window.localStorage.setItem("parceria", response.Item.parceria); } else { window.localStorage.removeItem("parceria"); }
                },
                (error: any) => {
                    console.log(error);
                    history.replace("/login/");
                });
        }
        consultar();
    }

    return (
        <>
            <IonMenu contentId={props.id} type="overlay" swipeGesture={false}>
                <IonContent>
                    <IonList id="inbox-list" style={{ paddingTop: isPlatform('ios') ? "56px" : "30px" }}>
                        <IonSelect style={{ fontSize: "24px", paddingLeft: "8px", paddingRight: "8px" }}
                            disabled={(window.localStorage.getItem("lojas") && JSON.parse(window.localStorage.getItem("lojas") || "").length == 1) ? true : false} name='Lojas' placeholder='Selecione a loja' title='Lojas' value={loja} multiple={false} cancelText="Cancel" okText="Ok" onIonChange={e => {
                                setLoja(e.detail.value);
                                carregarConfigWorkflowEmpresa(e.detail.value)
                            }} slot="end">
                            {JSON.parse(window.localStorage.getItem("lojas") || "[]").map((valor: any) => (
                                <IonSelectOption key={"lojaid" + valor.empresaLojaId} value={valor.empresaLojaId}>{valor.empresaLojaNome}</IonSelectOption>
                            ))}
                        </IonSelect>
                        <IonNote>{localStorage.getItem("nome") || ""}</IonNote>
                        {appPages.map((appPage, index) => {
                            return (
                                <IonMenuToggle key={index} hidden={appPage.hidden}>
                                    <IonItem routerLink={appPage.url} lines="none" detail={false}>
                                        <IonIcon slot="start" icon={appPage.iosIcon} />
                                        <IonLabel>{appPage.title}</IonLabel>
                                    </IonItem>
                                </IonMenuToggle>
                            );
                        })}
                    </IonList>



                </IonContent>
                <IonFooter className="ion-no-border">

                    <IonMenuToggle key="privacidade">
                        <IonItem routerLink='termospolitica' lines="full" detail={false}>
                            <IonIcon slot="start" icon={lockClosedOutline} />
                            <IonLabel>Privacidade e termos</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key="excluiresair">
                        <IonItem lines="none" button onClick={() => { setShowToast3(true); }} >
                            <IonIcon color='medium' slot="start" icon={closeOutline} />
                            <IonLabel>Excluir conta e sair</IonLabel>
                        </IonItem>
                    </IonMenuToggle>
                    <IonMenuToggle key="sair">
                        <IonItem lines="none" onClick={() => { setShowToast2(true); }} detail={false}>
                            <IonIcon slot="start" icon={exitOutline} />
                            <IonLabel>Sair</IonLabel>
                        </IonItem>
                    </IonMenuToggle>

                </IonFooter>

                <IonAlert
                    isOpen={showToast3}
                    onDidDismiss={() => setShowToast3(false)}
                    cssClass='my-custom-class'
                    header={'Excluir conta do Xeguei'}
                    message={'Deseja realmente <strong>excluir</strong> sua conta e sair? Essa ação não poderá ser desfeita.'}
                    buttons={[
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Excluir',
                            cssClass: 'primary',
                            handler: () => {
                                let cpf = window.localStorage.getItem("cpf") + '';
                                requestService(usuarioExcluir.url.replace("{id}", cpf), {
                                    method: usuarioExcluir.method,
                                    headers: [["token", localStorage.getItem("token") || ""]],
                                    credentials: 'include'
                                },
                                    history,
                                    (response: any) => {
                                        console.log("response", response);
                                        localStorage.clear();
                                        history.replace("/login/");
                                    },
                                    (error: any) => {
                                        console.log("error", error);
                                        localStorage.clear();
                                        history.replace("/login/");
                                    });
                            }
                        }
                    ]}
                />

                <IonAlert
                    isOpen={showToast2}
                    onDidDismiss={() => setShowToast2(false)}
                    cssClass='my-custom-class'
                    header={'Sair do Xeguei (10700)'}
                    message={'Deseja realmente <strong>desconectar</strong> de sua conta?'}
                    buttons={[
                        {
                            text: 'Cancelar',
                            role: 'cancel',
                        },
                        {
                            text: 'Sair',
                            cssClass: 'primary',
                            handler: () => {
                                requestService(deslogar.url, {
                                    method: deslogar.method,
                                    headers: [["token", localStorage.getItem("token") || ""]],
                                    credentials: 'include'
                                },
                                    history,
                                    (response: any) => {
                                        localStorage.clear();
                                        history.replace("/login/");
                                    },
                                    (error: any) => {
                                        localStorage.clear();
                                        history.replace("/login/");
                                    });
                            }
                        }
                    ]}
                />
            </IonMenu>
        </>
    );
}
export default Menu;