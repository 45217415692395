import { IonAlert, IonBackButton, IonButton, IonButtons, IonCard, IonCardContent, IonCardHeader, IonCardSubtitle, IonCardTitle, IonCol, IonContent, IonFab, IonFabButton, IonGrid, IonHeader, IonIcon, IonInfiniteScroll, IonInfiniteScrollContent, IonItem, IonLabel, IonLoading, IonModal, IonPage, IonRefresher, IonRefresherContent, IonRow, IonSearchbar, IonTitle, IonToolbar, useIonViewDidEnter } from '@ionic/react';
import { add, arrowBack } from 'ionicons/icons';
import React, { useContext, useRef, useState } from 'react';
import { useHistory } from 'react-router-dom';
import { excluiUsuario, listaEquipe, listaEquipePorPerfil, requestService, resetSenhaUsuario } from '../../../../../Utils/Services';
import { MessageContext } from '../../../../../contexts/MessageContext';
import UsuariosComandasCad from '../UsuariosComandasCad/UsuariosComandasCad';
import './UsuariosComandas.css';

type Props = {
  abrirMenu: boolean;
};

const UsuariosComandas: React.FC<Props> = (props: Props) => {

  const history = useHistory();
  const [showDetalhes, setShowDetalhes] = useState(false);
  const [arrayOperacoes, setArrayOperacoes] = useState<any>([]);
  const [desabilitarInfiniteScroll, setDesabilitarInfiniteScroll] = useState(false);
  const [operacaopk, setOperacaopk] = useState("");
  const [textToast, setTextToast] = useState("");
  const [mensagem, setMensagem] = useState("");
  const [cpf, setCpf] = useState("");
  const [showResetSenha, setShowResetSenha] = useState(false);
  const [showExcluirUsuario, setShowExcluirUsuario] = useState(false);
  const [showSucesso, setShowSucesso] = useState(false);
  const [showErro, setShowErro] = useState(false);
  const [showLoading, setShowLoading] = useState(false);
  const [pesquisa, setPesquisa] = useState<string>();
  const [usuario, setUsuario] = useState<any>();
  const [interno, setInterno] = useState<boolean>(true);
  const [acessos] = useState<any>(JSON.parse(window.localStorage.getItem("acessos") || '{}'));
  const { esconderMenu, setEsconderMenu } = useContext(MessageContext);
  const { nomeMenu, setNomeMenu } = useContext(MessageContext);
  const [showActionSheet, setShowActionSheet] = useState(false);


  const inputDetalhesRef = useRef<any>(null);

  useIonViewDidEnter(() => {
    setEsconderMenu(true);
    setNomeMenu("");
    carregarUsuarios();
  });

  function cpf_mask(v: string) {
    v = v.replace(/\D/g, ''); //Remove tudo o que não é dígito
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    v = v.replace(/(\d{3})(\d)/, '$1.$2'); //Coloca um ponto entre o terceiro e o quarto dígitos
    //de novo (para o segundo bloco de números)
    v = v.replace(/(\d{3})(\d{1,2})$/, '$1-$2'); //Coloca um hífen entre o terceiro e o quarto dígitos
    return v;
  }

  const loadItens = () => {

    if (arrayOperacoes === undefined || arrayOperacoes.length === 0) {
      return (
        <IonCard key="primeira">
          <IonCardHeader id="primeira" >
            <IonCardTitle>Aqui aparecem os clientes</IonCardTitle>
            <IonCardSubtitle></IonCardSubtitle>
            <IonLabel></IonLabel>
          </IonCardHeader>
          <IonCardContent>
          </IonCardContent>
        </IonCard>
      );
    }

    return arrayOperacoes.map((value: any, index: any, arr: any) =>

      <IonCard key={Math.random()} className="equipeCard" hidden={(!pesquisa || pesquisa === "" || value.nomeMinusculo?.indexOf(pesquisa.toString()) > -1) ? false : true} >
        <IonCardHeader id={value.pk} style={{ "background": value.color }}>
          <IonCardTitle>{value.nome}</IonCardTitle>
        </IonCardHeader>
        <IonCardContent>
          <IonGrid>
            <IonRow>
              <IonCol>
                <div className="equipe">
                  <IonLabel>Nome</IonLabel>
                  <IonLabel className="equipeTexto">{value.nome}</IonLabel>
                </div>

                <div className="equipe" hidden={!value.statusUsuario}>
                  <IonLabel>Status</IonLabel>
                  <IonLabel className="equipeTexto">{value.statusUsuario}</IonLabel>
                </div>

              </IonCol>
              <IonCol>
                <div className="equipe" hidden={!(value && value.lojas && value.lojas.length > 0)}>
                  <IonLabel>Loja</IonLabel>
                </div>
                {value.lojas?.map((valorLoja: any, indiceLoja: any, arrLoja: any) => (
                  <>
                    <div className="equipe" hidden={valorLoja.empresaLojaNome === ""}>
                      <IonLabel>{""}</IonLabel>
                      <IonLabel className="equipeTexto">{valorLoja.empresaLojaNome}</IonLabel>

                    </div>
                  </>))}
              </IonCol>
            </IonRow>
          </IonGrid>
          <IonItem lines="none">

            <IonButton size="small" fill='outline' shape="round" color="tertiary" slot="start" hidden={acessos?.cadastros != 'edicao'} onClick={() => { setUsuario(value); setShowDetalhes(true) }}>Alterar</IonButton>
            <IonButton size="small" fill='solid' shape="round" color="tertiary" slot="end" className="botaoExcluir" hidden={acessos?.cadastros != 'edicao'} onClick={() => { console.log(value.pk); setCpf(value.pk); setMensagem("Deseja realmente excluir a " + (window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa") + " " + value.nome + "?"); setShowExcluirUsuario(true) }}>Excluir conta</IonButton>
          </IonItem>
          <IonLoading
            cssClass='my-custom-class'
            isOpen={showLoading}
            onDidDismiss={() => { setShowLoading(false); }}
            message={'Registrando...'}

          />
        </IonCardContent>

      </IonCard>

    );

  }

  function carregarUsuarios() {
    const consultar = async () => {

      let token: string = "";
      const tkn = localStorage.getItem("token");
      if (typeof (tkn) === "string") {
        token = tkn;
      }

      let resp: any;
      let perfil = "comanda";

      console.log("perfil", perfil);
      if (!perfil) {
        resp = await fetch(listaEquipe.url,
          {
            method: listaEquipe.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
      }
      else {
        resp = await fetch(listaEquipePorPerfil.url.replace("{perfil}", perfil),
          {
            method: listaEquipePorPerfil.method,
            headers: [
              ["token", token]
            ],
            credentials: 'include'
          });
      }
      let equipe = await resp.json();

      if (resp.status === 400 || resp.status === 401) {
        history.replace("/login/");
      }
      else {
        setArrayOperacoes(equipe.Items.sort((a: any, b: any) => (a.nome?.toLowerCase() > b.nome?.toLowerCase()) ? 1 : -1));
        if (equipe.LastEvaluatedKey) {
          setOperacaopk(equipe.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
        }
      }
    }
    consultar();
  }

  async function carregarMaisUsuarios($event: CustomEvent<void>) {

    await carregarMaisUsuariosChamada().then(() => {
      console.log("terminou resultados");
    });
    ($event.target as HTMLIonInfiniteScrollElement).complete();


  }

  async function carregarMaisUsuariosChamada() {

    let token: string = "";
    const tkn = localStorage.getItem("token");
    if (typeof (tkn) === "string") {
      token = tkn;
    }

    const res: Response = await fetch(listaEquipe.url + "?pk=" + operacaopk,
      {
        method: listaEquipe.method,
        headers: [
          ["token", token]
        ],
        credentials: 'include'
      });
    await res
      .json()
      .then(async (operacoes) => {
        console.log(arrayOperacoes.length);
        for (let i = 0; i < operacoes.Items.length; i++) {
          console.log("resultados");
          arrayOperacoes.push(operacoes.Items[i]);
        }
        console.log(arrayOperacoes.length);
        if (operacoes.LastEvaluatedKey) {
          setOperacaopk(operacoes.LastEvaluatedKey.pk);
          setDesabilitarInfiniteScroll(false);
        }
        else {
          setOperacaopk("");
          setDesabilitarInfiniteScroll(true);
          console.log("finalizou");
        }
      })
      .catch(err => console.error(err));

    if (res.status === 400 || res.status === 401) history.replace("/login/");
  }

  async function closeModal() {
    setShowDetalhes(false);
    carregarUsuarios();
  }

  async function closeModalSimple() {
    setShowDetalhes(false);
  }

  function doRefresh(event: any) {
    carregarUsuarios();
    setTimeout(() => {
      event.detail.complete();
    }, 1200);
  }

  return (
    <IonPage>
      <IonHeader>
        <IonToolbar color="primary">
          <IonButtons slot="start">
            <IonBackButton icon={arrowBack} text=''></IonBackButton>
          </IonButtons>
          <IonTitle>{window.localStorage.getItem("nomeInternoMesasOuComandas") || "Mesas"}</IonTitle>
        </IonToolbar>
        <IonToolbar color="primary" >

          <IonSearchbar value={pesquisa} onIonClear={(e) => setPesquisa(undefined)} onIonChange={(e) => { setPesquisa(e.detail.value?.toLocaleLowerCase()) }} placeholder={'Nome da ' + (window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa")} ></IonSearchbar>
        </IonToolbar>
      </IonHeader>
      <IonContent className="equipecontent" fullscreen >
        <IonRefresher slot="fixed" onIonRefresh={doRefresh}>
          <IonRefresherContent></IonRefresherContent>
        </IonRefresher>

        <IonFab vertical="bottom" horizontal="center" slot="fixed" hidden={acessos?.cadastros != 'edicao'}>


          <IonFabButton color="tertiary" class="custom-fab-button" onClick={() => { setUsuario(null); setInterno(true); setShowDetalhes(true); }}>
            <IonIcon color="light" icon={add}></IonIcon>
          </IonFabButton>

        </IonFab>
        {loadItens()}
        <IonInfiniteScroll
          threshold="100px"
          onIonInfinite={(e: CustomEvent<void>) => carregarMaisUsuarios(e)}
          position="bottom"
          disabled={desabilitarInfiniteScroll}>
          <IonInfiniteScrollContent loadingSpinner="dots" loadingText="Carregando..."></IonInfiniteScrollContent>
        </IonInfiniteScroll>

        <IonAlert
          isOpen={showResetSenha}
          onDidDismiss={() => setShowResetSenha(false)}
          header={'Reset de Senha'}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(resetSenhaUsuario.url.replace("{cpf}", cpf), {
                  method: resetSenhaUsuario.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showExcluirUsuario}
          onDidDismiss={() => setShowExcluirUsuario(false)}
          header={'Exclusão de ' + (window.localStorage.getItem("nomeInternoMesaOuComanda") || "Mesa")}
          message={mensagem}
          buttons={[
            {
              text: 'Cancelar',
              role: 'cancel',
            },
            {
              text: 'Confirmar',
              cssClass: 'primary',
              handler: () => {
                requestService(excluiUsuario.url.replace("{cpf}", cpf.replaceAll(/#/g, "%23")), {
                  method: excluiUsuario.method,
                  headers: [["token", localStorage.getItem("token") || ""]],
                  credentials: 'include'
                },
                  history,
                  (response: any) => {
                    carregarUsuarios();
                    setTextToast(response.message);
                    setShowSucesso(true);
                  },
                  (error: any) => {
                    setTextToast(error.message);
                    setShowErro(true);
                  });
              }
            }
          ]}
        />

        <IonAlert
          isOpen={showSucesso}
          onDidDismiss={() => setShowSucesso(false)}
          header={'Sucesso'}
          message={textToast}
          buttons={['OK']}
        />

        <IonAlert
          isOpen={showErro}
          onDidDismiss={() => setShowErro(false)}
          header={'Erro'}
          message={textToast}
          buttons={['OK']}
        />
        <IonModal onDidPresent={() => {
          if (inputDetalhesRef.current) {
            inputDetalhesRef.current.setFocus();
          }
        }} isOpen={showDetalhes} onDidDismiss={() => { setShowDetalhes(false); }}>
          <UsuariosComandasCad usuario={usuario} inputRef={inputDetalhesRef} closeAction={closeModal} closeActionSimple={closeModalSimple} setCliente={() => { }}></UsuariosComandasCad>
        </IonModal>
      </IonContent>
    </IonPage>
  );
};

export default UsuariosComandas;
